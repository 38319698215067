var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/AdflowAPI.ts
var AdflowAPI_exports = {};
__export(AdflowAPI_exports, {
  AdElementDataSourceSchema: () => AdElementDataSourceSchema,
  AdElementImageSchema: () => AdElementImageSchema,
  AdElementSVGSchema: () => AdElementSVGSchema,
  AdElementSchema: () => AdElementSchema,
  AdElementTeamAssetSchema: () => AdElementTeamAssetSchema,
  AdElementTextSchema: () => AdElementTextSchema,
  AdElementTypeSchema: () => AdElementTypeSchema,
  AdElementVectorSchema: () => AdElementVectorSchema,
  AdTemplateDataSourceSchema: () => AdTemplateDataSourceSchema,
  AdTemplateDataSourcesSchema: () => AdTemplateDataSourcesSchema,
  AdTemplateDiplayOptionsSchema: () => AdTemplateDiplayOptionsSchema,
  AdTemplateSchema: () => AdTemplateSchema,
  BusinessEntitySchema: () => BusinessEntitySchema,
  DateTimeFormatSchema: () => DateTimeFormatSchema,
  DiplayOptionsTeamAssetSchema: () => DiplayOptionsTeamAssetSchema,
  DisplayOptionsOddsSchema: () => DisplayOptionsOddsSchema,
  FontFaceSchema: () => FontFaceSchema,
  isDisplayOptionsOddsType: () => isDisplayOptionsOddsType,
  isImageElement: () => isImageElement,
  isSVGElement: () => isSVGElement,
  isTeamAssetElement: () => isTeamAssetElement,
  isTextElement: () => isTextElement,
  isVectorElement: () => isVectorElement
});

// ../../node_modules/zod/lib/index.mjs
var util;
(function(util2) {
  util2.assertEqual = (val) => val;
  function assertIs(_arg) {
  }
  util2.assertIs = assertIs;
  function assertNever(_x) {
    throw new Error();
  }
  util2.assertNever = assertNever;
  util2.arrayToEnum = (items) => {
    const obj = {};
    for (const item of items) {
      obj[item] = item;
    }
    return obj;
  };
  util2.getValidEnumValues = (obj) => {
    const validKeys = util2.objectKeys(obj).filter((k) => typeof obj[obj[k]] !== "number");
    const filtered = {};
    for (const k of validKeys) {
      filtered[k] = obj[k];
    }
    return util2.objectValues(filtered);
  };
  util2.objectValues = (obj) => {
    return util2.objectKeys(obj).map(function(e) {
      return obj[e];
    });
  };
  util2.objectKeys = typeof Object.keys === "function" ? (obj) => Object.keys(obj) : (object) => {
    const keys = [];
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        keys.push(key);
      }
    }
    return keys;
  };
  util2.find = (arr, checker) => {
    for (const item of arr) {
      if (checker(item))
        return item;
    }
    return void 0;
  };
  util2.isInteger = typeof Number.isInteger === "function" ? (val) => Number.isInteger(val) : (val) => typeof val === "number" && isFinite(val) && Math.floor(val) === val;
  function joinValues(array, separator = " | ") {
    return array.map((val) => typeof val === "string" ? `'${val}'` : val).join(separator);
  }
  util2.joinValues = joinValues;
  util2.jsonStringifyReplacer = (_, value) => {
    if (typeof value === "bigint") {
      return value.toString();
    }
    return value;
  };
})(util || (util = {}));
var objectUtil;
(function(objectUtil2) {
  objectUtil2.mergeShapes = (first, second) => {
    return {
      ...first,
      ...second
      // second overwrites first
    };
  };
})(objectUtil || (objectUtil = {}));
var ZodParsedType = util.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]);
var getParsedType = (data) => {
  const t = typeof data;
  switch (t) {
    case "undefined":
      return ZodParsedType.undefined;
    case "string":
      return ZodParsedType.string;
    case "number":
      return isNaN(data) ? ZodParsedType.nan : ZodParsedType.number;
    case "boolean":
      return ZodParsedType.boolean;
    case "function":
      return ZodParsedType.function;
    case "bigint":
      return ZodParsedType.bigint;
    case "symbol":
      return ZodParsedType.symbol;
    case "object":
      if (Array.isArray(data)) {
        return ZodParsedType.array;
      }
      if (data === null) {
        return ZodParsedType.null;
      }
      if (data.then && typeof data.then === "function" && data.catch && typeof data.catch === "function") {
        return ZodParsedType.promise;
      }
      if (typeof Map !== "undefined" && data instanceof Map) {
        return ZodParsedType.map;
      }
      if (typeof Set !== "undefined" && data instanceof Set) {
        return ZodParsedType.set;
      }
      if (typeof Date !== "undefined" && data instanceof Date) {
        return ZodParsedType.date;
      }
      return ZodParsedType.object;
    default:
      return ZodParsedType.unknown;
  }
};
var ZodIssueCode = util.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]);
var quotelessJson = (obj) => {
  const json = JSON.stringify(obj, null, 2);
  return json.replace(/"([^"]+)":/g, "$1:");
};
var ZodError = class extends Error {
  constructor(issues) {
    super();
    this.issues = [];
    this.addIssue = (sub) => {
      this.issues = [...this.issues, sub];
    };
    this.addIssues = (subs = []) => {
      this.issues = [...this.issues, ...subs];
    };
    const actualProto = new.target.prototype;
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      this.__proto__ = actualProto;
    }
    this.name = "ZodError";
    this.issues = issues;
  }
  get errors() {
    return this.issues;
  }
  format(_mapper) {
    const mapper = _mapper || function(issue) {
      return issue.message;
    };
    const fieldErrors = { _errors: [] };
    const processError = (error) => {
      for (const issue of error.issues) {
        if (issue.code === "invalid_union") {
          issue.unionErrors.map(processError);
        } else if (issue.code === "invalid_return_type") {
          processError(issue.returnTypeError);
        } else if (issue.code === "invalid_arguments") {
          processError(issue.argumentsError);
        } else if (issue.path.length === 0) {
          fieldErrors._errors.push(mapper(issue));
        } else {
          let curr = fieldErrors;
          let i = 0;
          while (i < issue.path.length) {
            const el = issue.path[i];
            const terminal = i === issue.path.length - 1;
            if (!terminal) {
              curr[el] = curr[el] || { _errors: [] };
            } else {
              curr[el] = curr[el] || { _errors: [] };
              curr[el]._errors.push(mapper(issue));
            }
            curr = curr[el];
            i++;
          }
        }
      }
    };
    processError(this);
    return fieldErrors;
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, util.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(mapper = (issue) => issue.message) {
    const fieldErrors = {};
    const formErrors = [];
    for (const sub of this.issues) {
      if (sub.path.length > 0) {
        fieldErrors[sub.path[0]] = fieldErrors[sub.path[0]] || [];
        fieldErrors[sub.path[0]].push(mapper(sub));
      } else {
        formErrors.push(mapper(sub));
      }
    }
    return { formErrors, fieldErrors };
  }
  get formErrors() {
    return this.flatten();
  }
};
ZodError.create = (issues) => {
  const error = new ZodError(issues);
  return error;
};
var errorMap = (issue, _ctx) => {
  let message;
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = "Required";
      } else {
        message = `Expected ${issue.expected}, received ${issue.received}`;
      }
      break;
    case ZodIssueCode.invalid_literal:
      message = `Invalid literal value, expected ${JSON.stringify(issue.expected, util.jsonStringifyReplacer)}`;
      break;
    case ZodIssueCode.unrecognized_keys:
      message = `Unrecognized key(s) in object: ${util.joinValues(issue.keys, ", ")}`;
      break;
    case ZodIssueCode.invalid_union:
      message = `Invalid input`;
      break;
    case ZodIssueCode.invalid_union_discriminator:
      message = `Invalid discriminator value. Expected ${util.joinValues(issue.options)}`;
      break;
    case ZodIssueCode.invalid_enum_value:
      message = `Invalid enum value. Expected ${util.joinValues(issue.options)}, received '${issue.received}'`;
      break;
    case ZodIssueCode.invalid_arguments:
      message = `Invalid function arguments`;
      break;
    case ZodIssueCode.invalid_return_type:
      message = `Invalid function return type`;
      break;
    case ZodIssueCode.invalid_date:
      message = `Invalid date`;
      break;
    case ZodIssueCode.invalid_string:
      if (typeof issue.validation === "object") {
        if ("includes" in issue.validation) {
          message = `Invalid input: must include "${issue.validation.includes}"`;
          if (typeof issue.validation.position === "number") {
            message = `${message} at one or more positions greater than or equal to ${issue.validation.position}`;
          }
        } else if ("startsWith" in issue.validation) {
          message = `Invalid input: must start with "${issue.validation.startsWith}"`;
        } else if ("endsWith" in issue.validation) {
          message = `Invalid input: must end with "${issue.validation.endsWith}"`;
        } else {
          util.assertNever(issue.validation);
        }
      } else if (issue.validation !== "regex") {
        message = `Invalid ${issue.validation}`;
      } else {
        message = "Invalid";
      }
      break;
    case ZodIssueCode.too_small:
      if (issue.type === "array")
        message = `Array must contain ${issue.exact ? "exactly" : issue.inclusive ? `at least` : `more than`} ${issue.minimum} element(s)`;
      else if (issue.type === "string")
        message = `String must contain ${issue.exact ? "exactly" : issue.inclusive ? `at least` : `over`} ${issue.minimum} character(s)`;
      else if (issue.type === "number")
        message = `Number must be ${issue.exact ? `exactly equal to ` : issue.inclusive ? `greater than or equal to ` : `greater than `}${issue.minimum}`;
      else if (issue.type === "date")
        message = `Date must be ${issue.exact ? `exactly equal to ` : issue.inclusive ? `greater than or equal to ` : `greater than `}${new Date(Number(issue.minimum))}`;
      else
        message = "Invalid input";
      break;
    case ZodIssueCode.too_big:
      if (issue.type === "array")
        message = `Array must contain ${issue.exact ? `exactly` : issue.inclusive ? `at most` : `less than`} ${issue.maximum} element(s)`;
      else if (issue.type === "string")
        message = `String must contain ${issue.exact ? `exactly` : issue.inclusive ? `at most` : `under`} ${issue.maximum} character(s)`;
      else if (issue.type === "number")
        message = `Number must be ${issue.exact ? `exactly` : issue.inclusive ? `less than or equal to` : `less than`} ${issue.maximum}`;
      else if (issue.type === "bigint")
        message = `BigInt must be ${issue.exact ? `exactly` : issue.inclusive ? `less than or equal to` : `less than`} ${issue.maximum}`;
      else if (issue.type === "date")
        message = `Date must be ${issue.exact ? `exactly` : issue.inclusive ? `smaller than or equal to` : `smaller than`} ${new Date(Number(issue.maximum))}`;
      else
        message = "Invalid input";
      break;
    case ZodIssueCode.custom:
      message = `Invalid input`;
      break;
    case ZodIssueCode.invalid_intersection_types:
      message = `Intersection results could not be merged`;
      break;
    case ZodIssueCode.not_multiple_of:
      message = `Number must be a multiple of ${issue.multipleOf}`;
      break;
    case ZodIssueCode.not_finite:
      message = "Number must be finite";
      break;
    default:
      message = _ctx.defaultError;
      util.assertNever(issue);
  }
  return { message };
};
var overrideErrorMap = errorMap;
function setErrorMap(map) {
  overrideErrorMap = map;
}
function getErrorMap() {
  return overrideErrorMap;
}
var makeIssue = (params) => {
  const { data, path, errorMaps, issueData } = params;
  const fullPath = [...path, ...issueData.path || []];
  const fullIssue = {
    ...issueData,
    path: fullPath
  };
  let errorMessage = "";
  const maps = errorMaps.filter((m) => !!m).slice().reverse();
  for (const map of maps) {
    errorMessage = map(fullIssue, { data, defaultError: errorMessage }).message;
  }
  return {
    ...issueData,
    path: fullPath,
    message: issueData.message || errorMessage
  };
};
var EMPTY_PATH = [];
function addIssueToContext(ctx, issueData) {
  const issue = makeIssue({
    issueData,
    data: ctx.data,
    path: ctx.path,
    errorMaps: [
      ctx.common.contextualErrorMap,
      ctx.schemaErrorMap,
      getErrorMap(),
      errorMap
      // then global default map
    ].filter((x) => !!x)
  });
  ctx.common.issues.push(issue);
}
var ParseStatus = class _ParseStatus {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    if (this.value === "valid")
      this.value = "dirty";
  }
  abort() {
    if (this.value !== "aborted")
      this.value = "aborted";
  }
  static mergeArray(status, results) {
    const arrayValue = [];
    for (const s of results) {
      if (s.status === "aborted")
        return INVALID;
      if (s.status === "dirty")
        status.dirty();
      arrayValue.push(s.value);
    }
    return { status: status.value, value: arrayValue };
  }
  static async mergeObjectAsync(status, pairs) {
    const syncPairs = [];
    for (const pair of pairs) {
      syncPairs.push({
        key: await pair.key,
        value: await pair.value
      });
    }
    return _ParseStatus.mergeObjectSync(status, syncPairs);
  }
  static mergeObjectSync(status, pairs) {
    const finalObject = {};
    for (const pair of pairs) {
      const { key, value } = pair;
      if (key.status === "aborted")
        return INVALID;
      if (value.status === "aborted")
        return INVALID;
      if (key.status === "dirty")
        status.dirty();
      if (value.status === "dirty")
        status.dirty();
      if (key.value !== "__proto__" && (typeof value.value !== "undefined" || pair.alwaysSet)) {
        finalObject[key.value] = value.value;
      }
    }
    return { status: status.value, value: finalObject };
  }
};
var INVALID = Object.freeze({
  status: "aborted"
});
var DIRTY = (value) => ({ status: "dirty", value });
var OK = (value) => ({ status: "valid", value });
var isAborted = (x) => x.status === "aborted";
var isDirty = (x) => x.status === "dirty";
var isValid = (x) => x.status === "valid";
var isAsync = (x) => typeof Promise !== "undefined" && x instanceof Promise;
var errorUtil;
(function(errorUtil2) {
  errorUtil2.errToObj = (message) => typeof message === "string" ? { message } : message || {};
  errorUtil2.toString = (message) => typeof message === "string" ? message : message === null || message === void 0 ? void 0 : message.message;
})(errorUtil || (errorUtil = {}));
var ParseInputLazyPath = class {
  constructor(parent, value, path, key) {
    this._cachedPath = [];
    this.parent = parent;
    this.data = value;
    this._path = path;
    this._key = key;
  }
  get path() {
    if (!this._cachedPath.length) {
      if (this._key instanceof Array) {
        this._cachedPath.push(...this._path, ...this._key);
      } else {
        this._cachedPath.push(...this._path, this._key);
      }
    }
    return this._cachedPath;
  }
};
var handleResult = (ctx, result) => {
  if (isValid(result)) {
    return { success: true, data: result.value };
  } else {
    if (!ctx.common.issues.length) {
      throw new Error("Validation failed but no issues detected.");
    }
    return {
      success: false,
      get error() {
        if (this._error)
          return this._error;
        const error = new ZodError(ctx.common.issues);
        this._error = error;
        return this._error;
      }
    };
  }
};
function processCreateParams(params) {
  if (!params)
    return {};
  const { errorMap: errorMap2, invalid_type_error, required_error, description } = params;
  if (errorMap2 && (invalid_type_error || required_error)) {
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  }
  if (errorMap2)
    return { errorMap: errorMap2, description };
  const customMap = (iss, ctx) => {
    if (iss.code !== "invalid_type")
      return { message: ctx.defaultError };
    if (typeof ctx.data === "undefined") {
      return { message: required_error !== null && required_error !== void 0 ? required_error : ctx.defaultError };
    }
    return { message: invalid_type_error !== null && invalid_type_error !== void 0 ? invalid_type_error : ctx.defaultError };
  };
  return { errorMap: customMap, description };
}
var ZodType = class {
  constructor(def) {
    this.spa = this.safeParseAsync;
    this._def = def;
    this.parse = this.parse.bind(this);
    this.safeParse = this.safeParse.bind(this);
    this.parseAsync = this.parseAsync.bind(this);
    this.safeParseAsync = this.safeParseAsync.bind(this);
    this.spa = this.spa.bind(this);
    this.refine = this.refine.bind(this);
    this.refinement = this.refinement.bind(this);
    this.superRefine = this.superRefine.bind(this);
    this.optional = this.optional.bind(this);
    this.nullable = this.nullable.bind(this);
    this.nullish = this.nullish.bind(this);
    this.array = this.array.bind(this);
    this.promise = this.promise.bind(this);
    this.or = this.or.bind(this);
    this.and = this.and.bind(this);
    this.transform = this.transform.bind(this);
    this.brand = this.brand.bind(this);
    this.default = this.default.bind(this);
    this.catch = this.catch.bind(this);
    this.describe = this.describe.bind(this);
    this.pipe = this.pipe.bind(this);
    this.readonly = this.readonly.bind(this);
    this.isNullable = this.isNullable.bind(this);
    this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(input) {
    return getParsedType(input.data);
  }
  _getOrReturnCtx(input, ctx) {
    return ctx || {
      common: input.parent.common,
      data: input.data,
      parsedType: getParsedType(input.data),
      schemaErrorMap: this._def.errorMap,
      path: input.path,
      parent: input.parent
    };
  }
  _processInputParams(input) {
    return {
      status: new ParseStatus(),
      ctx: {
        common: input.parent.common,
        data: input.data,
        parsedType: getParsedType(input.data),
        schemaErrorMap: this._def.errorMap,
        path: input.path,
        parent: input.parent
      }
    };
  }
  _parseSync(input) {
    const result = this._parse(input);
    if (isAsync(result)) {
      throw new Error("Synchronous parse encountered promise.");
    }
    return result;
  }
  _parseAsync(input) {
    const result = this._parse(input);
    return Promise.resolve(result);
  }
  parse(data, params) {
    const result = this.safeParse(data, params);
    if (result.success)
      return result.data;
    throw result.error;
  }
  safeParse(data, params) {
    var _a;
    const ctx = {
      common: {
        issues: [],
        async: (_a = params === null || params === void 0 ? void 0 : params.async) !== null && _a !== void 0 ? _a : false,
        contextualErrorMap: params === null || params === void 0 ? void 0 : params.errorMap
      },
      path: (params === null || params === void 0 ? void 0 : params.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data,
      parsedType: getParsedType(data)
    };
    const result = this._parseSync({ data, path: ctx.path, parent: ctx });
    return handleResult(ctx, result);
  }
  async parseAsync(data, params) {
    const result = await this.safeParseAsync(data, params);
    if (result.success)
      return result.data;
    throw result.error;
  }
  async safeParseAsync(data, params) {
    const ctx = {
      common: {
        issues: [],
        contextualErrorMap: params === null || params === void 0 ? void 0 : params.errorMap,
        async: true
      },
      path: (params === null || params === void 0 ? void 0 : params.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data,
      parsedType: getParsedType(data)
    };
    const maybeAsyncResult = this._parse({ data, path: ctx.path, parent: ctx });
    const result = await (isAsync(maybeAsyncResult) ? maybeAsyncResult : Promise.resolve(maybeAsyncResult));
    return handleResult(ctx, result);
  }
  refine(check, message) {
    const getIssueProperties = (val) => {
      if (typeof message === "string" || typeof message === "undefined") {
        return { message };
      } else if (typeof message === "function") {
        return message(val);
      } else {
        return message;
      }
    };
    return this._refinement((val, ctx) => {
      const result = check(val);
      const setError = () => ctx.addIssue({
        code: ZodIssueCode.custom,
        ...getIssueProperties(val)
      });
      if (typeof Promise !== "undefined" && result instanceof Promise) {
        return result.then((data) => {
          if (!data) {
            setError();
            return false;
          } else {
            return true;
          }
        });
      }
      if (!result) {
        setError();
        return false;
      } else {
        return true;
      }
    });
  }
  refinement(check, refinementData) {
    return this._refinement((val, ctx) => {
      if (!check(val)) {
        ctx.addIssue(typeof refinementData === "function" ? refinementData(val, ctx) : refinementData);
        return false;
      } else {
        return true;
      }
    });
  }
  _refinement(refinement) {
    return new ZodEffects({
      schema: this,
      typeName: ZodFirstPartyTypeKind.ZodEffects,
      effect: { type: "refinement", refinement }
    });
  }
  superRefine(refinement) {
    return this._refinement(refinement);
  }
  optional() {
    return ZodOptional.create(this, this._def);
  }
  nullable() {
    return ZodNullable.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return ZodArray.create(this, this._def);
  }
  promise() {
    return ZodPromise.create(this, this._def);
  }
  or(option) {
    return ZodUnion.create([this, option], this._def);
  }
  and(incoming) {
    return ZodIntersection.create(this, incoming, this._def);
  }
  transform(transform) {
    return new ZodEffects({
      ...processCreateParams(this._def),
      schema: this,
      typeName: ZodFirstPartyTypeKind.ZodEffects,
      effect: { type: "transform", transform }
    });
  }
  default(def) {
    const defaultValueFunc = typeof def === "function" ? def : () => def;
    return new ZodDefault({
      ...processCreateParams(this._def),
      innerType: this,
      defaultValue: defaultValueFunc,
      typeName: ZodFirstPartyTypeKind.ZodDefault
    });
  }
  brand() {
    return new ZodBranded({
      typeName: ZodFirstPartyTypeKind.ZodBranded,
      type: this,
      ...processCreateParams(this._def)
    });
  }
  catch(def) {
    const catchValueFunc = typeof def === "function" ? def : () => def;
    return new ZodCatch({
      ...processCreateParams(this._def),
      innerType: this,
      catchValue: catchValueFunc,
      typeName: ZodFirstPartyTypeKind.ZodCatch
    });
  }
  describe(description) {
    const This = this.constructor;
    return new This({
      ...this._def,
      description
    });
  }
  pipe(target) {
    return ZodPipeline.create(this, target);
  }
  readonly() {
    return ZodReadonly.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
};
var cuidRegex = /^c[^\s-]{8,}$/i;
var cuid2Regex = /^[a-z][a-z0-9]*$/;
var ulidRegex = /[0-9A-HJKMNP-TV-Z]{26}/;
var uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;
var emailRegex = /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i;
var emojiRegex = /^(\p{Extended_Pictographic}|\p{Emoji_Component})+$/u;
var ipv4Regex = /^(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))$/;
var ipv6Regex = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/;
var datetimeRegex = (args) => {
  if (args.precision) {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${args.precision}}(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${args.precision}}Z$`);
    }
  } else if (args.precision === 0) {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$`);
    }
  } else {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?Z$`);
    }
  }
};
function isValidIP(ip, version) {
  if ((version === "v4" || !version) && ipv4Regex.test(ip)) {
    return true;
  }
  if ((version === "v6" || !version) && ipv6Regex.test(ip)) {
    return true;
  }
  return false;
}
var ZodString = class _ZodString extends ZodType {
  constructor() {
    super(...arguments);
    this._regex = (regex, validation, message) => this.refinement((data) => regex.test(data), {
      validation,
      code: ZodIssueCode.invalid_string,
      ...errorUtil.errToObj(message)
    });
    this.nonempty = (message) => this.min(1, errorUtil.errToObj(message));
    this.trim = () => new _ZodString({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
    this.toLowerCase = () => new _ZodString({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
    this.toUpperCase = () => new _ZodString({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = String(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.string) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(
        ctx2,
        {
          code: ZodIssueCode.invalid_type,
          expected: ZodParsedType.string,
          received: ctx2.parsedType
        }
        //
      );
      return INVALID;
    }
    const status = new ParseStatus();
    let ctx = void 0;
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        if (input.data.length < check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            minimum: check.value,
            type: "string",
            inclusive: true,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        if (input.data.length > check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            maximum: check.value,
            type: "string",
            inclusive: true,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "length") {
        const tooBig = input.data.length > check.value;
        const tooSmall = input.data.length < check.value;
        if (tooBig || tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          if (tooBig) {
            addIssueToContext(ctx, {
              code: ZodIssueCode.too_big,
              maximum: check.value,
              type: "string",
              inclusive: true,
              exact: true,
              message: check.message
            });
          } else if (tooSmall) {
            addIssueToContext(ctx, {
              code: ZodIssueCode.too_small,
              minimum: check.value,
              type: "string",
              inclusive: true,
              exact: true,
              message: check.message
            });
          }
          status.dirty();
        }
      } else if (check.kind === "email") {
        if (!emailRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "email",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "emoji") {
        if (!emojiRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "emoji",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "uuid") {
        if (!uuidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "uuid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "cuid") {
        if (!cuidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "cuid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "cuid2") {
        if (!cuid2Regex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "cuid2",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "ulid") {
        if (!ulidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "ulid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "url") {
        try {
          new URL(input.data);
        } catch (_a) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "url",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "regex") {
        check.regex.lastIndex = 0;
        const testResult = check.regex.test(input.data);
        if (!testResult) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "regex",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "trim") {
        input.data = input.data.trim();
      } else if (check.kind === "includes") {
        if (!input.data.includes(check.value, check.position)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { includes: check.value, position: check.position },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "toLowerCase") {
        input.data = input.data.toLowerCase();
      } else if (check.kind === "toUpperCase") {
        input.data = input.data.toUpperCase();
      } else if (check.kind === "startsWith") {
        if (!input.data.startsWith(check.value)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { startsWith: check.value },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "endsWith") {
        if (!input.data.endsWith(check.value)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { endsWith: check.value },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "datetime") {
        const regex = datetimeRegex(check);
        if (!regex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: "datetime",
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "ip") {
        if (!isValidIP(input.data, check.version)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "ip",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  _addCheck(check) {
    return new _ZodString({
      ...this._def,
      checks: [...this._def.checks, check]
    });
  }
  email(message) {
    return this._addCheck({ kind: "email", ...errorUtil.errToObj(message) });
  }
  url(message) {
    return this._addCheck({ kind: "url", ...errorUtil.errToObj(message) });
  }
  emoji(message) {
    return this._addCheck({ kind: "emoji", ...errorUtil.errToObj(message) });
  }
  uuid(message) {
    return this._addCheck({ kind: "uuid", ...errorUtil.errToObj(message) });
  }
  cuid(message) {
    return this._addCheck({ kind: "cuid", ...errorUtil.errToObj(message) });
  }
  cuid2(message) {
    return this._addCheck({ kind: "cuid2", ...errorUtil.errToObj(message) });
  }
  ulid(message) {
    return this._addCheck({ kind: "ulid", ...errorUtil.errToObj(message) });
  }
  ip(options) {
    return this._addCheck({ kind: "ip", ...errorUtil.errToObj(options) });
  }
  datetime(options) {
    var _a;
    if (typeof options === "string") {
      return this._addCheck({
        kind: "datetime",
        precision: null,
        offset: false,
        message: options
      });
    }
    return this._addCheck({
      kind: "datetime",
      precision: typeof (options === null || options === void 0 ? void 0 : options.precision) === "undefined" ? null : options === null || options === void 0 ? void 0 : options.precision,
      offset: (_a = options === null || options === void 0 ? void 0 : options.offset) !== null && _a !== void 0 ? _a : false,
      ...errorUtil.errToObj(options === null || options === void 0 ? void 0 : options.message)
    });
  }
  regex(regex, message) {
    return this._addCheck({
      kind: "regex",
      regex,
      ...errorUtil.errToObj(message)
    });
  }
  includes(value, options) {
    return this._addCheck({
      kind: "includes",
      value,
      position: options === null || options === void 0 ? void 0 : options.position,
      ...errorUtil.errToObj(options === null || options === void 0 ? void 0 : options.message)
    });
  }
  startsWith(value, message) {
    return this._addCheck({
      kind: "startsWith",
      value,
      ...errorUtil.errToObj(message)
    });
  }
  endsWith(value, message) {
    return this._addCheck({
      kind: "endsWith",
      value,
      ...errorUtil.errToObj(message)
    });
  }
  min(minLength, message) {
    return this._addCheck({
      kind: "min",
      value: minLength,
      ...errorUtil.errToObj(message)
    });
  }
  max(maxLength, message) {
    return this._addCheck({
      kind: "max",
      value: maxLength,
      ...errorUtil.errToObj(message)
    });
  }
  length(len, message) {
    return this._addCheck({
      kind: "length",
      value: len,
      ...errorUtil.errToObj(message)
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((ch) => ch.kind === "datetime");
  }
  get isEmail() {
    return !!this._def.checks.find((ch) => ch.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((ch) => ch.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((ch) => ch.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((ch) => ch.kind === "uuid");
  }
  get isCUID() {
    return !!this._def.checks.find((ch) => ch.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((ch) => ch.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((ch) => ch.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((ch) => ch.kind === "ip");
  }
  get minLength() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min;
  }
  get maxLength() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max;
  }
};
ZodString.create = (params) => {
  var _a;
  return new ZodString({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodString,
    coerce: (_a = params === null || params === void 0 ? void 0 : params.coerce) !== null && _a !== void 0 ? _a : false,
    ...processCreateParams(params)
  });
};
function floatSafeRemainder(val, step) {
  const valDecCount = (val.toString().split(".")[1] || "").length;
  const stepDecCount = (step.toString().split(".")[1] || "").length;
  const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
  const valInt = parseInt(val.toFixed(decCount).replace(".", ""));
  const stepInt = parseInt(step.toFixed(decCount).replace(".", ""));
  return valInt % stepInt / Math.pow(10, decCount);
}
var ZodNumber = class _ZodNumber extends ZodType {
  constructor() {
    super(...arguments);
    this.min = this.gte;
    this.max = this.lte;
    this.step = this.multipleOf;
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = Number(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.number) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.number,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    let ctx = void 0;
    const status = new ParseStatus();
    for (const check of this._def.checks) {
      if (check.kind === "int") {
        if (!util.isInteger(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_type,
            expected: "integer",
            received: "float",
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "min") {
        const tooSmall = check.inclusive ? input.data < check.value : input.data <= check.value;
        if (tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            minimum: check.value,
            type: "number",
            inclusive: check.inclusive,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        const tooBig = check.inclusive ? input.data > check.value : input.data >= check.value;
        if (tooBig) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            maximum: check.value,
            type: "number",
            inclusive: check.inclusive,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "multipleOf") {
        if (floatSafeRemainder(input.data, check.value) !== 0) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_multiple_of,
            multipleOf: check.value,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "finite") {
        if (!Number.isFinite(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_finite,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  gte(value, message) {
    return this.setLimit("min", value, true, errorUtil.toString(message));
  }
  gt(value, message) {
    return this.setLimit("min", value, false, errorUtil.toString(message));
  }
  lte(value, message) {
    return this.setLimit("max", value, true, errorUtil.toString(message));
  }
  lt(value, message) {
    return this.setLimit("max", value, false, errorUtil.toString(message));
  }
  setLimit(kind, value, inclusive, message) {
    return new _ZodNumber({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind,
          value,
          inclusive,
          message: errorUtil.toString(message)
        }
      ]
    });
  }
  _addCheck(check) {
    return new _ZodNumber({
      ...this._def,
      checks: [...this._def.checks, check]
    });
  }
  int(message) {
    return this._addCheck({
      kind: "int",
      message: errorUtil.toString(message)
    });
  }
  positive(message) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  negative(message) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  nonpositive(message) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  nonnegative(message) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  multipleOf(value, message) {
    return this._addCheck({
      kind: "multipleOf",
      value,
      message: errorUtil.toString(message)
    });
  }
  finite(message) {
    return this._addCheck({
      kind: "finite",
      message: errorUtil.toString(message)
    });
  }
  safe(message) {
    return this._addCheck({
      kind: "min",
      inclusive: true,
      value: Number.MIN_SAFE_INTEGER,
      message: errorUtil.toString(message)
    })._addCheck({
      kind: "max",
      inclusive: true,
      value: Number.MAX_SAFE_INTEGER,
      message: errorUtil.toString(message)
    });
  }
  get minValue() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min;
  }
  get maxValue() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max;
  }
  get isInt() {
    return !!this._def.checks.find((ch) => ch.kind === "int" || ch.kind === "multipleOf" && util.isInteger(ch.value));
  }
  get isFinite() {
    let max = null, min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "finite" || ch.kind === "int" || ch.kind === "multipleOf") {
        return true;
      } else if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      } else if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return Number.isFinite(min) && Number.isFinite(max);
  }
};
ZodNumber.create = (params) => {
  return new ZodNumber({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodNumber,
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false,
    ...processCreateParams(params)
  });
};
var ZodBigInt = class _ZodBigInt extends ZodType {
  constructor() {
    super(...arguments);
    this.min = this.gte;
    this.max = this.lte;
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = BigInt(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.bigint) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.bigint,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    let ctx = void 0;
    const status = new ParseStatus();
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        const tooSmall = check.inclusive ? input.data < check.value : input.data <= check.value;
        if (tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            type: "bigint",
            minimum: check.value,
            inclusive: check.inclusive,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        const tooBig = check.inclusive ? input.data > check.value : input.data >= check.value;
        if (tooBig) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            type: "bigint",
            maximum: check.value,
            inclusive: check.inclusive,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "multipleOf") {
        if (input.data % check.value !== BigInt(0)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_multiple_of,
            multipleOf: check.value,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  gte(value, message) {
    return this.setLimit("min", value, true, errorUtil.toString(message));
  }
  gt(value, message) {
    return this.setLimit("min", value, false, errorUtil.toString(message));
  }
  lte(value, message) {
    return this.setLimit("max", value, true, errorUtil.toString(message));
  }
  lt(value, message) {
    return this.setLimit("max", value, false, errorUtil.toString(message));
  }
  setLimit(kind, value, inclusive, message) {
    return new _ZodBigInt({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind,
          value,
          inclusive,
          message: errorUtil.toString(message)
        }
      ]
    });
  }
  _addCheck(check) {
    return new _ZodBigInt({
      ...this._def,
      checks: [...this._def.checks, check]
    });
  }
  positive(message) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  negative(message) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  nonpositive(message) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  nonnegative(message) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  multipleOf(value, message) {
    return this._addCheck({
      kind: "multipleOf",
      value,
      message: errorUtil.toString(message)
    });
  }
  get minValue() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min;
  }
  get maxValue() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max;
  }
};
ZodBigInt.create = (params) => {
  var _a;
  return new ZodBigInt({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodBigInt,
    coerce: (_a = params === null || params === void 0 ? void 0 : params.coerce) !== null && _a !== void 0 ? _a : false,
    ...processCreateParams(params)
  });
};
var ZodBoolean = class extends ZodType {
  _parse(input) {
    if (this._def.coerce) {
      input.data = Boolean(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.boolean) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.boolean,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodBoolean.create = (params) => {
  return new ZodBoolean({
    typeName: ZodFirstPartyTypeKind.ZodBoolean,
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false,
    ...processCreateParams(params)
  });
};
var ZodDate = class _ZodDate extends ZodType {
  _parse(input) {
    if (this._def.coerce) {
      input.data = new Date(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.date) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.date,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    if (isNaN(input.data.getTime())) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_date
      });
      return INVALID;
    }
    const status = new ParseStatus();
    let ctx = void 0;
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        if (input.data.getTime() < check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            message: check.message,
            inclusive: true,
            exact: false,
            minimum: check.value,
            type: "date"
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        if (input.data.getTime() > check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            message: check.message,
            inclusive: true,
            exact: false,
            maximum: check.value,
            type: "date"
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return {
      status: status.value,
      value: new Date(input.data.getTime())
    };
  }
  _addCheck(check) {
    return new _ZodDate({
      ...this._def,
      checks: [...this._def.checks, check]
    });
  }
  min(minDate, message) {
    return this._addCheck({
      kind: "min",
      value: minDate.getTime(),
      message: errorUtil.toString(message)
    });
  }
  max(maxDate, message) {
    return this._addCheck({
      kind: "max",
      value: maxDate.getTime(),
      message: errorUtil.toString(message)
    });
  }
  get minDate() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min != null ? new Date(min) : null;
  }
  get maxDate() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max != null ? new Date(max) : null;
  }
};
ZodDate.create = (params) => {
  return new ZodDate({
    checks: [],
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false,
    typeName: ZodFirstPartyTypeKind.ZodDate,
    ...processCreateParams(params)
  });
};
var ZodSymbol = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.symbol) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.symbol,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodSymbol.create = (params) => {
  return new ZodSymbol({
    typeName: ZodFirstPartyTypeKind.ZodSymbol,
    ...processCreateParams(params)
  });
};
var ZodUndefined = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.undefined) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.undefined,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodUndefined.create = (params) => {
  return new ZodUndefined({
    typeName: ZodFirstPartyTypeKind.ZodUndefined,
    ...processCreateParams(params)
  });
};
var ZodNull = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.null) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.null,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodNull.create = (params) => {
  return new ZodNull({
    typeName: ZodFirstPartyTypeKind.ZodNull,
    ...processCreateParams(params)
  });
};
var ZodAny = class extends ZodType {
  constructor() {
    super(...arguments);
    this._any = true;
  }
  _parse(input) {
    return OK(input.data);
  }
};
ZodAny.create = (params) => {
  return new ZodAny({
    typeName: ZodFirstPartyTypeKind.ZodAny,
    ...processCreateParams(params)
  });
};
var ZodUnknown = class extends ZodType {
  constructor() {
    super(...arguments);
    this._unknown = true;
  }
  _parse(input) {
    return OK(input.data);
  }
};
ZodUnknown.create = (params) => {
  return new ZodUnknown({
    typeName: ZodFirstPartyTypeKind.ZodUnknown,
    ...processCreateParams(params)
  });
};
var ZodNever = class extends ZodType {
  _parse(input) {
    const ctx = this._getOrReturnCtx(input);
    addIssueToContext(ctx, {
      code: ZodIssueCode.invalid_type,
      expected: ZodParsedType.never,
      received: ctx.parsedType
    });
    return INVALID;
  }
};
ZodNever.create = (params) => {
  return new ZodNever({
    typeName: ZodFirstPartyTypeKind.ZodNever,
    ...processCreateParams(params)
  });
};
var ZodVoid = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.undefined) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.void,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodVoid.create = (params) => {
  return new ZodVoid({
    typeName: ZodFirstPartyTypeKind.ZodVoid,
    ...processCreateParams(params)
  });
};
var ZodArray = class _ZodArray extends ZodType {
  _parse(input) {
    const { ctx, status } = this._processInputParams(input);
    const def = this._def;
    if (ctx.parsedType !== ZodParsedType.array) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.array,
        received: ctx.parsedType
      });
      return INVALID;
    }
    if (def.exactLength !== null) {
      const tooBig = ctx.data.length > def.exactLength.value;
      const tooSmall = ctx.data.length < def.exactLength.value;
      if (tooBig || tooSmall) {
        addIssueToContext(ctx, {
          code: tooBig ? ZodIssueCode.too_big : ZodIssueCode.too_small,
          minimum: tooSmall ? def.exactLength.value : void 0,
          maximum: tooBig ? def.exactLength.value : void 0,
          type: "array",
          inclusive: true,
          exact: true,
          message: def.exactLength.message
        });
        status.dirty();
      }
    }
    if (def.minLength !== null) {
      if (ctx.data.length < def.minLength.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_small,
          minimum: def.minLength.value,
          type: "array",
          inclusive: true,
          exact: false,
          message: def.minLength.message
        });
        status.dirty();
      }
    }
    if (def.maxLength !== null) {
      if (ctx.data.length > def.maxLength.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_big,
          maximum: def.maxLength.value,
          type: "array",
          inclusive: true,
          exact: false,
          message: def.maxLength.message
        });
        status.dirty();
      }
    }
    if (ctx.common.async) {
      return Promise.all([...ctx.data].map((item, i) => {
        return def.type._parseAsync(new ParseInputLazyPath(ctx, item, ctx.path, i));
      })).then((result2) => {
        return ParseStatus.mergeArray(status, result2);
      });
    }
    const result = [...ctx.data].map((item, i) => {
      return def.type._parseSync(new ParseInputLazyPath(ctx, item, ctx.path, i));
    });
    return ParseStatus.mergeArray(status, result);
  }
  get element() {
    return this._def.type;
  }
  min(minLength, message) {
    return new _ZodArray({
      ...this._def,
      minLength: { value: minLength, message: errorUtil.toString(message) }
    });
  }
  max(maxLength, message) {
    return new _ZodArray({
      ...this._def,
      maxLength: { value: maxLength, message: errorUtil.toString(message) }
    });
  }
  length(len, message) {
    return new _ZodArray({
      ...this._def,
      exactLength: { value: len, message: errorUtil.toString(message) }
    });
  }
  nonempty(message) {
    return this.min(1, message);
  }
};
ZodArray.create = (schema, params) => {
  return new ZodArray({
    type: schema,
    minLength: null,
    maxLength: null,
    exactLength: null,
    typeName: ZodFirstPartyTypeKind.ZodArray,
    ...processCreateParams(params)
  });
};
function deepPartialify(schema) {
  if (schema instanceof ZodObject) {
    const newShape = {};
    for (const key in schema.shape) {
      const fieldSchema = schema.shape[key];
      newShape[key] = ZodOptional.create(deepPartialify(fieldSchema));
    }
    return new ZodObject({
      ...schema._def,
      shape: () => newShape
    });
  } else if (schema instanceof ZodArray) {
    return new ZodArray({
      ...schema._def,
      type: deepPartialify(schema.element)
    });
  } else if (schema instanceof ZodOptional) {
    return ZodOptional.create(deepPartialify(schema.unwrap()));
  } else if (schema instanceof ZodNullable) {
    return ZodNullable.create(deepPartialify(schema.unwrap()));
  } else if (schema instanceof ZodTuple) {
    return ZodTuple.create(schema.items.map((item) => deepPartialify(item)));
  } else {
    return schema;
  }
}
var ZodObject = class _ZodObject extends ZodType {
  constructor() {
    super(...arguments);
    this._cached = null;
    this.nonstrict = this.passthrough;
    this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const shape = this._def.shape();
    const keys = util.objectKeys(shape);
    return this._cached = { shape, keys };
  }
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.object) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    const { status, ctx } = this._processInputParams(input);
    const { shape, keys: shapeKeys } = this._getCached();
    const extraKeys = [];
    if (!(this._def.catchall instanceof ZodNever && this._def.unknownKeys === "strip")) {
      for (const key in ctx.data) {
        if (!shapeKeys.includes(key)) {
          extraKeys.push(key);
        }
      }
    }
    const pairs = [];
    for (const key of shapeKeys) {
      const keyValidator = shape[key];
      const value = ctx.data[key];
      pairs.push({
        key: { status: "valid", value: key },
        value: keyValidator._parse(new ParseInputLazyPath(ctx, value, ctx.path, key)),
        alwaysSet: key in ctx.data
      });
    }
    if (this._def.catchall instanceof ZodNever) {
      const unknownKeys = this._def.unknownKeys;
      if (unknownKeys === "passthrough") {
        for (const key of extraKeys) {
          pairs.push({
            key: { status: "valid", value: key },
            value: { status: "valid", value: ctx.data[key] }
          });
        }
      } else if (unknownKeys === "strict") {
        if (extraKeys.length > 0) {
          addIssueToContext(ctx, {
            code: ZodIssueCode.unrecognized_keys,
            keys: extraKeys
          });
          status.dirty();
        }
      } else if (unknownKeys === "strip")
        ;
      else {
        throw new Error(`Internal ZodObject error: invalid unknownKeys value.`);
      }
    } else {
      const catchall = this._def.catchall;
      for (const key of extraKeys) {
        const value = ctx.data[key];
        pairs.push({
          key: { status: "valid", value: key },
          value: catchall._parse(
            new ParseInputLazyPath(ctx, value, ctx.path, key)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: key in ctx.data
        });
      }
    }
    if (ctx.common.async) {
      return Promise.resolve().then(async () => {
        const syncPairs = [];
        for (const pair of pairs) {
          const key = await pair.key;
          syncPairs.push({
            key,
            value: await pair.value,
            alwaysSet: pair.alwaysSet
          });
        }
        return syncPairs;
      }).then((syncPairs) => {
        return ParseStatus.mergeObjectSync(status, syncPairs);
      });
    } else {
      return ParseStatus.mergeObjectSync(status, pairs);
    }
  }
  get shape() {
    return this._def.shape();
  }
  strict(message) {
    errorUtil.errToObj;
    return new _ZodObject({
      ...this._def,
      unknownKeys: "strict",
      ...message !== void 0 ? {
        errorMap: (issue, ctx) => {
          var _a, _b, _c, _d;
          const defaultError = (_c = (_b = (_a = this._def).errorMap) === null || _b === void 0 ? void 0 : _b.call(_a, issue, ctx).message) !== null && _c !== void 0 ? _c : ctx.defaultError;
          if (issue.code === "unrecognized_keys")
            return {
              message: (_d = errorUtil.errToObj(message).message) !== null && _d !== void 0 ? _d : defaultError
            };
          return {
            message: defaultError
          };
        }
      } : {}
    });
  }
  strip() {
    return new _ZodObject({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new _ZodObject({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(augmentation) {
    return new _ZodObject({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...augmentation
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(merging) {
    const merged = new _ZodObject({
      unknownKeys: merging._def.unknownKeys,
      catchall: merging._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...merging._def.shape()
      }),
      typeName: ZodFirstPartyTypeKind.ZodObject
    });
    return merged;
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(key, schema) {
    return this.augment({ [key]: schema });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(index) {
    return new _ZodObject({
      ...this._def,
      catchall: index
    });
  }
  pick(mask) {
    const shape = {};
    util.objectKeys(mask).forEach((key) => {
      if (mask[key] && this.shape[key]) {
        shape[key] = this.shape[key];
      }
    });
    return new _ZodObject({
      ...this._def,
      shape: () => shape
    });
  }
  omit(mask) {
    const shape = {};
    util.objectKeys(this.shape).forEach((key) => {
      if (!mask[key]) {
        shape[key] = this.shape[key];
      }
    });
    return new _ZodObject({
      ...this._def,
      shape: () => shape
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return deepPartialify(this);
  }
  partial(mask) {
    const newShape = {};
    util.objectKeys(this.shape).forEach((key) => {
      const fieldSchema = this.shape[key];
      if (mask && !mask[key]) {
        newShape[key] = fieldSchema;
      } else {
        newShape[key] = fieldSchema.optional();
      }
    });
    return new _ZodObject({
      ...this._def,
      shape: () => newShape
    });
  }
  required(mask) {
    const newShape = {};
    util.objectKeys(this.shape).forEach((key) => {
      if (mask && !mask[key]) {
        newShape[key] = this.shape[key];
      } else {
        const fieldSchema = this.shape[key];
        let newField = fieldSchema;
        while (newField instanceof ZodOptional) {
          newField = newField._def.innerType;
        }
        newShape[key] = newField;
      }
    });
    return new _ZodObject({
      ...this._def,
      shape: () => newShape
    });
  }
  keyof() {
    return createZodEnum(util.objectKeys(this.shape));
  }
};
ZodObject.create = (shape, params) => {
  return new ZodObject({
    shape: () => shape,
    unknownKeys: "strip",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject,
    ...processCreateParams(params)
  });
};
ZodObject.strictCreate = (shape, params) => {
  return new ZodObject({
    shape: () => shape,
    unknownKeys: "strict",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject,
    ...processCreateParams(params)
  });
};
ZodObject.lazycreate = (shape, params) => {
  return new ZodObject({
    shape,
    unknownKeys: "strip",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject,
    ...processCreateParams(params)
  });
};
var ZodUnion = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const options = this._def.options;
    function handleResults(results) {
      for (const result of results) {
        if (result.result.status === "valid") {
          return result.result;
        }
      }
      for (const result of results) {
        if (result.result.status === "dirty") {
          ctx.common.issues.push(...result.ctx.common.issues);
          return result.result;
        }
      }
      const unionErrors = results.map((result) => new ZodError(result.ctx.common.issues));
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union,
        unionErrors
      });
      return INVALID;
    }
    if (ctx.common.async) {
      return Promise.all(options.map(async (option) => {
        const childCtx = {
          ...ctx,
          common: {
            ...ctx.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await option._parseAsync({
            data: ctx.data,
            path: ctx.path,
            parent: childCtx
          }),
          ctx: childCtx
        };
      })).then(handleResults);
    } else {
      let dirty = void 0;
      const issues = [];
      for (const option of options) {
        const childCtx = {
          ...ctx,
          common: {
            ...ctx.common,
            issues: []
          },
          parent: null
        };
        const result = option._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: childCtx
        });
        if (result.status === "valid") {
          return result;
        } else if (result.status === "dirty" && !dirty) {
          dirty = { result, ctx: childCtx };
        }
        if (childCtx.common.issues.length) {
          issues.push(childCtx.common.issues);
        }
      }
      if (dirty) {
        ctx.common.issues.push(...dirty.ctx.common.issues);
        return dirty.result;
      }
      const unionErrors = issues.map((issues2) => new ZodError(issues2));
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union,
        unionErrors
      });
      return INVALID;
    }
  }
  get options() {
    return this._def.options;
  }
};
ZodUnion.create = (types, params) => {
  return new ZodUnion({
    options: types,
    typeName: ZodFirstPartyTypeKind.ZodUnion,
    ...processCreateParams(params)
  });
};
var getDiscriminator = (type) => {
  if (type instanceof ZodLazy) {
    return getDiscriminator(type.schema);
  } else if (type instanceof ZodEffects) {
    return getDiscriminator(type.innerType());
  } else if (type instanceof ZodLiteral) {
    return [type.value];
  } else if (type instanceof ZodEnum) {
    return type.options;
  } else if (type instanceof ZodNativeEnum) {
    return Object.keys(type.enum);
  } else if (type instanceof ZodDefault) {
    return getDiscriminator(type._def.innerType);
  } else if (type instanceof ZodUndefined) {
    return [void 0];
  } else if (type instanceof ZodNull) {
    return [null];
  } else {
    return null;
  }
};
var ZodDiscriminatedUnion = class _ZodDiscriminatedUnion extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.object) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const discriminator = this.discriminator;
    const discriminatorValue = ctx.data[discriminator];
    const option = this.optionsMap.get(discriminatorValue);
    if (!option) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union_discriminator,
        options: Array.from(this.optionsMap.keys()),
        path: [discriminator]
      });
      return INVALID;
    }
    if (ctx.common.async) {
      return option._parseAsync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
    } else {
      return option._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
    }
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(discriminator, options, params) {
    const optionsMap = /* @__PURE__ */ new Map();
    for (const type of options) {
      const discriminatorValues = getDiscriminator(type.shape[discriminator]);
      if (!discriminatorValues) {
        throw new Error(`A discriminator value for key \`${discriminator}\` could not be extracted from all schema options`);
      }
      for (const value of discriminatorValues) {
        if (optionsMap.has(value)) {
          throw new Error(`Discriminator property ${String(discriminator)} has duplicate value ${String(value)}`);
        }
        optionsMap.set(value, type);
      }
    }
    return new _ZodDiscriminatedUnion({
      typeName: ZodFirstPartyTypeKind.ZodDiscriminatedUnion,
      discriminator,
      options,
      optionsMap,
      ...processCreateParams(params)
    });
  }
};
function mergeValues(a, b) {
  const aType = getParsedType(a);
  const bType = getParsedType(b);
  if (a === b) {
    return { valid: true, data: a };
  } else if (aType === ZodParsedType.object && bType === ZodParsedType.object) {
    const bKeys = util.objectKeys(b);
    const sharedKeys = util.objectKeys(a).filter((key) => bKeys.indexOf(key) !== -1);
    const newObj = { ...a, ...b };
    for (const key of sharedKeys) {
      const sharedValue = mergeValues(a[key], b[key]);
      if (!sharedValue.valid) {
        return { valid: false };
      }
      newObj[key] = sharedValue.data;
    }
    return { valid: true, data: newObj };
  } else if (aType === ZodParsedType.array && bType === ZodParsedType.array) {
    if (a.length !== b.length) {
      return { valid: false };
    }
    const newArray = [];
    for (let index = 0; index < a.length; index++) {
      const itemA = a[index];
      const itemB = b[index];
      const sharedValue = mergeValues(itemA, itemB);
      if (!sharedValue.valid) {
        return { valid: false };
      }
      newArray.push(sharedValue.data);
    }
    return { valid: true, data: newArray };
  } else if (aType === ZodParsedType.date && bType === ZodParsedType.date && +a === +b) {
    return { valid: true, data: a };
  } else {
    return { valid: false };
  }
}
var ZodIntersection = class extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    const handleParsed = (parsedLeft, parsedRight) => {
      if (isAborted(parsedLeft) || isAborted(parsedRight)) {
        return INVALID;
      }
      const merged = mergeValues(parsedLeft.value, parsedRight.value);
      if (!merged.valid) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.invalid_intersection_types
        });
        return INVALID;
      }
      if (isDirty(parsedLeft) || isDirty(parsedRight)) {
        status.dirty();
      }
      return { status: status.value, value: merged.data };
    };
    if (ctx.common.async) {
      return Promise.all([
        this._def.left._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        }),
        this._def.right._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        })
      ]).then(([left, right]) => handleParsed(left, right));
    } else {
      return handleParsed(this._def.left._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      }), this._def.right._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      }));
    }
  }
};
ZodIntersection.create = (left, right, params) => {
  return new ZodIntersection({
    left,
    right,
    typeName: ZodFirstPartyTypeKind.ZodIntersection,
    ...processCreateParams(params)
  });
};
var ZodTuple = class _ZodTuple extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.array) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.array,
        received: ctx.parsedType
      });
      return INVALID;
    }
    if (ctx.data.length < this._def.items.length) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.too_small,
        minimum: this._def.items.length,
        inclusive: true,
        exact: false,
        type: "array"
      });
      return INVALID;
    }
    const rest = this._def.rest;
    if (!rest && ctx.data.length > this._def.items.length) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.too_big,
        maximum: this._def.items.length,
        inclusive: true,
        exact: false,
        type: "array"
      });
      status.dirty();
    }
    const items = [...ctx.data].map((item, itemIndex) => {
      const schema = this._def.items[itemIndex] || this._def.rest;
      if (!schema)
        return null;
      return schema._parse(new ParseInputLazyPath(ctx, item, ctx.path, itemIndex));
    }).filter((x) => !!x);
    if (ctx.common.async) {
      return Promise.all(items).then((results) => {
        return ParseStatus.mergeArray(status, results);
      });
    } else {
      return ParseStatus.mergeArray(status, items);
    }
  }
  get items() {
    return this._def.items;
  }
  rest(rest) {
    return new _ZodTuple({
      ...this._def,
      rest
    });
  }
};
ZodTuple.create = (schemas, params) => {
  if (!Array.isArray(schemas)) {
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  }
  return new ZodTuple({
    items: schemas,
    typeName: ZodFirstPartyTypeKind.ZodTuple,
    rest: null,
    ...processCreateParams(params)
  });
};
var ZodRecord = class _ZodRecord extends ZodType {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.object) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const pairs = [];
    const keyType = this._def.keyType;
    const valueType = this._def.valueType;
    for (const key in ctx.data) {
      pairs.push({
        key: keyType._parse(new ParseInputLazyPath(ctx, key, ctx.path, key)),
        value: valueType._parse(new ParseInputLazyPath(ctx, ctx.data[key], ctx.path, key))
      });
    }
    if (ctx.common.async) {
      return ParseStatus.mergeObjectAsync(status, pairs);
    } else {
      return ParseStatus.mergeObjectSync(status, pairs);
    }
  }
  get element() {
    return this._def.valueType;
  }
  static create(first, second, third) {
    if (second instanceof ZodType) {
      return new _ZodRecord({
        keyType: first,
        valueType: second,
        typeName: ZodFirstPartyTypeKind.ZodRecord,
        ...processCreateParams(third)
      });
    }
    return new _ZodRecord({
      keyType: ZodString.create(),
      valueType: first,
      typeName: ZodFirstPartyTypeKind.ZodRecord,
      ...processCreateParams(second)
    });
  }
};
var ZodMap = class extends ZodType {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.map) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.map,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const keyType = this._def.keyType;
    const valueType = this._def.valueType;
    const pairs = [...ctx.data.entries()].map(([key, value], index) => {
      return {
        key: keyType._parse(new ParseInputLazyPath(ctx, key, ctx.path, [index, "key"])),
        value: valueType._parse(new ParseInputLazyPath(ctx, value, ctx.path, [index, "value"]))
      };
    });
    if (ctx.common.async) {
      const finalMap = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const pair of pairs) {
          const key = await pair.key;
          const value = await pair.value;
          if (key.status === "aborted" || value.status === "aborted") {
            return INVALID;
          }
          if (key.status === "dirty" || value.status === "dirty") {
            status.dirty();
          }
          finalMap.set(key.value, value.value);
        }
        return { status: status.value, value: finalMap };
      });
    } else {
      const finalMap = /* @__PURE__ */ new Map();
      for (const pair of pairs) {
        const key = pair.key;
        const value = pair.value;
        if (key.status === "aborted" || value.status === "aborted") {
          return INVALID;
        }
        if (key.status === "dirty" || value.status === "dirty") {
          status.dirty();
        }
        finalMap.set(key.value, value.value);
      }
      return { status: status.value, value: finalMap };
    }
  }
};
ZodMap.create = (keyType, valueType, params) => {
  return new ZodMap({
    valueType,
    keyType,
    typeName: ZodFirstPartyTypeKind.ZodMap,
    ...processCreateParams(params)
  });
};
var ZodSet = class _ZodSet extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.set) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.set,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const def = this._def;
    if (def.minSize !== null) {
      if (ctx.data.size < def.minSize.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_small,
          minimum: def.minSize.value,
          type: "set",
          inclusive: true,
          exact: false,
          message: def.minSize.message
        });
        status.dirty();
      }
    }
    if (def.maxSize !== null) {
      if (ctx.data.size > def.maxSize.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_big,
          maximum: def.maxSize.value,
          type: "set",
          inclusive: true,
          exact: false,
          message: def.maxSize.message
        });
        status.dirty();
      }
    }
    const valueType = this._def.valueType;
    function finalizeSet(elements2) {
      const parsedSet = /* @__PURE__ */ new Set();
      for (const element of elements2) {
        if (element.status === "aborted")
          return INVALID;
        if (element.status === "dirty")
          status.dirty();
        parsedSet.add(element.value);
      }
      return { status: status.value, value: parsedSet };
    }
    const elements = [...ctx.data.values()].map((item, i) => valueType._parse(new ParseInputLazyPath(ctx, item, ctx.path, i)));
    if (ctx.common.async) {
      return Promise.all(elements).then((elements2) => finalizeSet(elements2));
    } else {
      return finalizeSet(elements);
    }
  }
  min(minSize, message) {
    return new _ZodSet({
      ...this._def,
      minSize: { value: minSize, message: errorUtil.toString(message) }
    });
  }
  max(maxSize, message) {
    return new _ZodSet({
      ...this._def,
      maxSize: { value: maxSize, message: errorUtil.toString(message) }
    });
  }
  size(size, message) {
    return this.min(size, message).max(size, message);
  }
  nonempty(message) {
    return this.min(1, message);
  }
};
ZodSet.create = (valueType, params) => {
  return new ZodSet({
    valueType,
    minSize: null,
    maxSize: null,
    typeName: ZodFirstPartyTypeKind.ZodSet,
    ...processCreateParams(params)
  });
};
var ZodFunction = class _ZodFunction extends ZodType {
  constructor() {
    super(...arguments);
    this.validate = this.implement;
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.function) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.function,
        received: ctx.parsedType
      });
      return INVALID;
    }
    function makeArgsIssue(args, error) {
      return makeIssue({
        data: args,
        path: ctx.path,
        errorMaps: [
          ctx.common.contextualErrorMap,
          ctx.schemaErrorMap,
          getErrorMap(),
          errorMap
        ].filter((x) => !!x),
        issueData: {
          code: ZodIssueCode.invalid_arguments,
          argumentsError: error
        }
      });
    }
    function makeReturnsIssue(returns, error) {
      return makeIssue({
        data: returns,
        path: ctx.path,
        errorMaps: [
          ctx.common.contextualErrorMap,
          ctx.schemaErrorMap,
          getErrorMap(),
          errorMap
        ].filter((x) => !!x),
        issueData: {
          code: ZodIssueCode.invalid_return_type,
          returnTypeError: error
        }
      });
    }
    const params = { errorMap: ctx.common.contextualErrorMap };
    const fn = ctx.data;
    if (this._def.returns instanceof ZodPromise) {
      const me = this;
      return OK(async function(...args) {
        const error = new ZodError([]);
        const parsedArgs = await me._def.args.parseAsync(args, params).catch((e) => {
          error.addIssue(makeArgsIssue(args, e));
          throw error;
        });
        const result = await Reflect.apply(fn, this, parsedArgs);
        const parsedReturns = await me._def.returns._def.type.parseAsync(result, params).catch((e) => {
          error.addIssue(makeReturnsIssue(result, e));
          throw error;
        });
        return parsedReturns;
      });
    } else {
      const me = this;
      return OK(function(...args) {
        const parsedArgs = me._def.args.safeParse(args, params);
        if (!parsedArgs.success) {
          throw new ZodError([makeArgsIssue(args, parsedArgs.error)]);
        }
        const result = Reflect.apply(fn, this, parsedArgs.data);
        const parsedReturns = me._def.returns.safeParse(result, params);
        if (!parsedReturns.success) {
          throw new ZodError([makeReturnsIssue(result, parsedReturns.error)]);
        }
        return parsedReturns.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...items) {
    return new _ZodFunction({
      ...this._def,
      args: ZodTuple.create(items).rest(ZodUnknown.create())
    });
  }
  returns(returnType) {
    return new _ZodFunction({
      ...this._def,
      returns: returnType
    });
  }
  implement(func) {
    const validatedFunc = this.parse(func);
    return validatedFunc;
  }
  strictImplement(func) {
    const validatedFunc = this.parse(func);
    return validatedFunc;
  }
  static create(args, returns, params) {
    return new _ZodFunction({
      args: args ? args : ZodTuple.create([]).rest(ZodUnknown.create()),
      returns: returns || ZodUnknown.create(),
      typeName: ZodFirstPartyTypeKind.ZodFunction,
      ...processCreateParams(params)
    });
  }
};
var ZodLazy = class extends ZodType {
  get schema() {
    return this._def.getter();
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const lazySchema = this._def.getter();
    return lazySchema._parse({ data: ctx.data, path: ctx.path, parent: ctx });
  }
};
ZodLazy.create = (getter, params) => {
  return new ZodLazy({
    getter,
    typeName: ZodFirstPartyTypeKind.ZodLazy,
    ...processCreateParams(params)
  });
};
var ZodLiteral = class extends ZodType {
  _parse(input) {
    if (input.data !== this._def.value) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_literal,
        expected: this._def.value
      });
      return INVALID;
    }
    return { status: "valid", value: input.data };
  }
  get value() {
    return this._def.value;
  }
};
ZodLiteral.create = (value, params) => {
  return new ZodLiteral({
    value,
    typeName: ZodFirstPartyTypeKind.ZodLiteral,
    ...processCreateParams(params)
  });
};
function createZodEnum(values, params) {
  return new ZodEnum({
    values,
    typeName: ZodFirstPartyTypeKind.ZodEnum,
    ...processCreateParams(params)
  });
}
var ZodEnum = class _ZodEnum extends ZodType {
  _parse(input) {
    if (typeof input.data !== "string") {
      const ctx = this._getOrReturnCtx(input);
      const expectedValues = this._def.values;
      addIssueToContext(ctx, {
        expected: util.joinValues(expectedValues),
        received: ctx.parsedType,
        code: ZodIssueCode.invalid_type
      });
      return INVALID;
    }
    if (this._def.values.indexOf(input.data) === -1) {
      const ctx = this._getOrReturnCtx(input);
      const expectedValues = this._def.values;
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_enum_value,
        options: expectedValues
      });
      return INVALID;
    }
    return OK(input.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  get Values() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  get Enum() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  extract(values) {
    return _ZodEnum.create(values);
  }
  exclude(values) {
    return _ZodEnum.create(this.options.filter((opt) => !values.includes(opt)));
  }
};
ZodEnum.create = createZodEnum;
var ZodNativeEnum = class extends ZodType {
  _parse(input) {
    const nativeEnumValues = util.getValidEnumValues(this._def.values);
    const ctx = this._getOrReturnCtx(input);
    if (ctx.parsedType !== ZodParsedType.string && ctx.parsedType !== ZodParsedType.number) {
      const expectedValues = util.objectValues(nativeEnumValues);
      addIssueToContext(ctx, {
        expected: util.joinValues(expectedValues),
        received: ctx.parsedType,
        code: ZodIssueCode.invalid_type
      });
      return INVALID;
    }
    if (nativeEnumValues.indexOf(input.data) === -1) {
      const expectedValues = util.objectValues(nativeEnumValues);
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_enum_value,
        options: expectedValues
      });
      return INVALID;
    }
    return OK(input.data);
  }
  get enum() {
    return this._def.values;
  }
};
ZodNativeEnum.create = (values, params) => {
  return new ZodNativeEnum({
    values,
    typeName: ZodFirstPartyTypeKind.ZodNativeEnum,
    ...processCreateParams(params)
  });
};
var ZodPromise = class extends ZodType {
  unwrap() {
    return this._def.type;
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.promise && ctx.common.async === false) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.promise,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const promisified = ctx.parsedType === ZodParsedType.promise ? ctx.data : Promise.resolve(ctx.data);
    return OK(promisified.then((data) => {
      return this._def.type.parseAsync(data, {
        path: ctx.path,
        errorMap: ctx.common.contextualErrorMap
      });
    }));
  }
};
ZodPromise.create = (schema, params) => {
  return new ZodPromise({
    type: schema,
    typeName: ZodFirstPartyTypeKind.ZodPromise,
    ...processCreateParams(params)
  });
};
var ZodEffects = class extends ZodType {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === ZodFirstPartyTypeKind.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    const effect = this._def.effect || null;
    const checkCtx = {
      addIssue: (arg) => {
        addIssueToContext(ctx, arg);
        if (arg.fatal) {
          status.abort();
        } else {
          status.dirty();
        }
      },
      get path() {
        return ctx.path;
      }
    };
    checkCtx.addIssue = checkCtx.addIssue.bind(checkCtx);
    if (effect.type === "preprocess") {
      const processed = effect.transform(ctx.data, checkCtx);
      if (ctx.common.issues.length) {
        return {
          status: "dirty",
          value: ctx.data
        };
      }
      if (ctx.common.async) {
        return Promise.resolve(processed).then((processed2) => {
          return this._def.schema._parseAsync({
            data: processed2,
            path: ctx.path,
            parent: ctx
          });
        });
      } else {
        return this._def.schema._parseSync({
          data: processed,
          path: ctx.path,
          parent: ctx
        });
      }
    }
    if (effect.type === "refinement") {
      const executeRefinement = (acc) => {
        const result = effect.refinement(acc, checkCtx);
        if (ctx.common.async) {
          return Promise.resolve(result);
        }
        if (result instanceof Promise) {
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        }
        return acc;
      };
      if (ctx.common.async === false) {
        const inner = this._def.schema._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (inner.status === "aborted")
          return INVALID;
        if (inner.status === "dirty")
          status.dirty();
        executeRefinement(inner.value);
        return { status: status.value, value: inner.value };
      } else {
        return this._def.schema._parseAsync({ data: ctx.data, path: ctx.path, parent: ctx }).then((inner) => {
          if (inner.status === "aborted")
            return INVALID;
          if (inner.status === "dirty")
            status.dirty();
          return executeRefinement(inner.value).then(() => {
            return { status: status.value, value: inner.value };
          });
        });
      }
    }
    if (effect.type === "transform") {
      if (ctx.common.async === false) {
        const base = this._def.schema._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (!isValid(base))
          return base;
        const result = effect.transform(base.value, checkCtx);
        if (result instanceof Promise) {
          throw new Error(`Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.`);
        }
        return { status: status.value, value: result };
      } else {
        return this._def.schema._parseAsync({ data: ctx.data, path: ctx.path, parent: ctx }).then((base) => {
          if (!isValid(base))
            return base;
          return Promise.resolve(effect.transform(base.value, checkCtx)).then((result) => ({ status: status.value, value: result }));
        });
      }
    }
    util.assertNever(effect);
  }
};
ZodEffects.create = (schema, effect, params) => {
  return new ZodEffects({
    schema,
    typeName: ZodFirstPartyTypeKind.ZodEffects,
    effect,
    ...processCreateParams(params)
  });
};
ZodEffects.createWithPreprocess = (preprocess, schema, params) => {
  return new ZodEffects({
    schema,
    effect: { type: "preprocess", transform: preprocess },
    typeName: ZodFirstPartyTypeKind.ZodEffects,
    ...processCreateParams(params)
  });
};
var ZodOptional = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType === ZodParsedType.undefined) {
      return OK(void 0);
    }
    return this._def.innerType._parse(input);
  }
  unwrap() {
    return this._def.innerType;
  }
};
ZodOptional.create = (type, params) => {
  return new ZodOptional({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodOptional,
    ...processCreateParams(params)
  });
};
var ZodNullable = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType === ZodParsedType.null) {
      return OK(null);
    }
    return this._def.innerType._parse(input);
  }
  unwrap() {
    return this._def.innerType;
  }
};
ZodNullable.create = (type, params) => {
  return new ZodNullable({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodNullable,
    ...processCreateParams(params)
  });
};
var ZodDefault = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    let data = ctx.data;
    if (ctx.parsedType === ZodParsedType.undefined) {
      data = this._def.defaultValue();
    }
    return this._def.innerType._parse({
      data,
      path: ctx.path,
      parent: ctx
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
};
ZodDefault.create = (type, params) => {
  return new ZodDefault({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodDefault,
    defaultValue: typeof params.default === "function" ? params.default : () => params.default,
    ...processCreateParams(params)
  });
};
var ZodCatch = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const newCtx = {
      ...ctx,
      common: {
        ...ctx.common,
        issues: []
      }
    };
    const result = this._def.innerType._parse({
      data: newCtx.data,
      path: newCtx.path,
      parent: {
        ...newCtx
      }
    });
    if (isAsync(result)) {
      return result.then((result2) => {
        return {
          status: "valid",
          value: result2.status === "valid" ? result2.value : this._def.catchValue({
            get error() {
              return new ZodError(newCtx.common.issues);
            },
            input: newCtx.data
          })
        };
      });
    } else {
      return {
        status: "valid",
        value: result.status === "valid" ? result.value : this._def.catchValue({
          get error() {
            return new ZodError(newCtx.common.issues);
          },
          input: newCtx.data
        })
      };
    }
  }
  removeCatch() {
    return this._def.innerType;
  }
};
ZodCatch.create = (type, params) => {
  return new ZodCatch({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodCatch,
    catchValue: typeof params.catch === "function" ? params.catch : () => params.catch,
    ...processCreateParams(params)
  });
};
var ZodNaN = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.nan) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.nan,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return { status: "valid", value: input.data };
  }
};
ZodNaN.create = (params) => {
  return new ZodNaN({
    typeName: ZodFirstPartyTypeKind.ZodNaN,
    ...processCreateParams(params)
  });
};
var BRAND = Symbol("zod_brand");
var ZodBranded = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const data = ctx.data;
    return this._def.type._parse({
      data,
      path: ctx.path,
      parent: ctx
    });
  }
  unwrap() {
    return this._def.type;
  }
};
var ZodPipeline = class _ZodPipeline extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.common.async) {
      const handleAsync = async () => {
        const inResult = await this._def.in._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (inResult.status === "aborted")
          return INVALID;
        if (inResult.status === "dirty") {
          status.dirty();
          return DIRTY(inResult.value);
        } else {
          return this._def.out._parseAsync({
            data: inResult.value,
            path: ctx.path,
            parent: ctx
          });
        }
      };
      return handleAsync();
    } else {
      const inResult = this._def.in._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
      if (inResult.status === "aborted")
        return INVALID;
      if (inResult.status === "dirty") {
        status.dirty();
        return {
          status: "dirty",
          value: inResult.value
        };
      } else {
        return this._def.out._parseSync({
          data: inResult.value,
          path: ctx.path,
          parent: ctx
        });
      }
    }
  }
  static create(a, b) {
    return new _ZodPipeline({
      in: a,
      out: b,
      typeName: ZodFirstPartyTypeKind.ZodPipeline
    });
  }
};
var ZodReadonly = class extends ZodType {
  _parse(input) {
    const result = this._def.innerType._parse(input);
    if (isValid(result)) {
      result.value = Object.freeze(result.value);
    }
    return result;
  }
};
ZodReadonly.create = (type, params) => {
  return new ZodReadonly({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodReadonly,
    ...processCreateParams(params)
  });
};
var custom = (check, params = {}, fatal) => {
  if (check)
    return ZodAny.create().superRefine((data, ctx) => {
      var _a, _b;
      if (!check(data)) {
        const p = typeof params === "function" ? params(data) : typeof params === "string" ? { message: params } : params;
        const _fatal = (_b = (_a = p.fatal) !== null && _a !== void 0 ? _a : fatal) !== null && _b !== void 0 ? _b : true;
        const p2 = typeof p === "string" ? { message: p } : p;
        ctx.addIssue({ code: "custom", ...p2, fatal: _fatal });
      }
    });
  return ZodAny.create();
};
var late = {
  object: ZodObject.lazycreate
};
var ZodFirstPartyTypeKind;
(function(ZodFirstPartyTypeKind2) {
  ZodFirstPartyTypeKind2["ZodString"] = "ZodString";
  ZodFirstPartyTypeKind2["ZodNumber"] = "ZodNumber";
  ZodFirstPartyTypeKind2["ZodNaN"] = "ZodNaN";
  ZodFirstPartyTypeKind2["ZodBigInt"] = "ZodBigInt";
  ZodFirstPartyTypeKind2["ZodBoolean"] = "ZodBoolean";
  ZodFirstPartyTypeKind2["ZodDate"] = "ZodDate";
  ZodFirstPartyTypeKind2["ZodSymbol"] = "ZodSymbol";
  ZodFirstPartyTypeKind2["ZodUndefined"] = "ZodUndefined";
  ZodFirstPartyTypeKind2["ZodNull"] = "ZodNull";
  ZodFirstPartyTypeKind2["ZodAny"] = "ZodAny";
  ZodFirstPartyTypeKind2["ZodUnknown"] = "ZodUnknown";
  ZodFirstPartyTypeKind2["ZodNever"] = "ZodNever";
  ZodFirstPartyTypeKind2["ZodVoid"] = "ZodVoid";
  ZodFirstPartyTypeKind2["ZodArray"] = "ZodArray";
  ZodFirstPartyTypeKind2["ZodObject"] = "ZodObject";
  ZodFirstPartyTypeKind2["ZodUnion"] = "ZodUnion";
  ZodFirstPartyTypeKind2["ZodDiscriminatedUnion"] = "ZodDiscriminatedUnion";
  ZodFirstPartyTypeKind2["ZodIntersection"] = "ZodIntersection";
  ZodFirstPartyTypeKind2["ZodTuple"] = "ZodTuple";
  ZodFirstPartyTypeKind2["ZodRecord"] = "ZodRecord";
  ZodFirstPartyTypeKind2["ZodMap"] = "ZodMap";
  ZodFirstPartyTypeKind2["ZodSet"] = "ZodSet";
  ZodFirstPartyTypeKind2["ZodFunction"] = "ZodFunction";
  ZodFirstPartyTypeKind2["ZodLazy"] = "ZodLazy";
  ZodFirstPartyTypeKind2["ZodLiteral"] = "ZodLiteral";
  ZodFirstPartyTypeKind2["ZodEnum"] = "ZodEnum";
  ZodFirstPartyTypeKind2["ZodEffects"] = "ZodEffects";
  ZodFirstPartyTypeKind2["ZodNativeEnum"] = "ZodNativeEnum";
  ZodFirstPartyTypeKind2["ZodOptional"] = "ZodOptional";
  ZodFirstPartyTypeKind2["ZodNullable"] = "ZodNullable";
  ZodFirstPartyTypeKind2["ZodDefault"] = "ZodDefault";
  ZodFirstPartyTypeKind2["ZodCatch"] = "ZodCatch";
  ZodFirstPartyTypeKind2["ZodPromise"] = "ZodPromise";
  ZodFirstPartyTypeKind2["ZodBranded"] = "ZodBranded";
  ZodFirstPartyTypeKind2["ZodPipeline"] = "ZodPipeline";
  ZodFirstPartyTypeKind2["ZodReadonly"] = "ZodReadonly";
})(ZodFirstPartyTypeKind || (ZodFirstPartyTypeKind = {}));
var instanceOfType = (cls, params = {
  message: `Input not instance of ${cls.name}`
}) => custom((data) => data instanceof cls, params);
var stringType = ZodString.create;
var numberType = ZodNumber.create;
var nanType = ZodNaN.create;
var bigIntType = ZodBigInt.create;
var booleanType = ZodBoolean.create;
var dateType = ZodDate.create;
var symbolType = ZodSymbol.create;
var undefinedType = ZodUndefined.create;
var nullType = ZodNull.create;
var anyType = ZodAny.create;
var unknownType = ZodUnknown.create;
var neverType = ZodNever.create;
var voidType = ZodVoid.create;
var arrayType = ZodArray.create;
var objectType = ZodObject.create;
var strictObjectType = ZodObject.strictCreate;
var unionType = ZodUnion.create;
var discriminatedUnionType = ZodDiscriminatedUnion.create;
var intersectionType = ZodIntersection.create;
var tupleType = ZodTuple.create;
var recordType = ZodRecord.create;
var mapType = ZodMap.create;
var setType = ZodSet.create;
var functionType = ZodFunction.create;
var lazyType = ZodLazy.create;
var literalType = ZodLiteral.create;
var enumType = ZodEnum.create;
var nativeEnumType = ZodNativeEnum.create;
var promiseType = ZodPromise.create;
var effectsType = ZodEffects.create;
var optionalType = ZodOptional.create;
var nullableType = ZodNullable.create;
var preprocessType = ZodEffects.createWithPreprocess;
var pipelineType = ZodPipeline.create;
var ostring = () => stringType().optional();
var onumber = () => numberType().optional();
var oboolean = () => booleanType().optional();
var coerce = {
  string: (arg) => ZodString.create({ ...arg, coerce: true }),
  number: (arg) => ZodNumber.create({ ...arg, coerce: true }),
  boolean: (arg) => ZodBoolean.create({
    ...arg,
    coerce: true
  }),
  bigint: (arg) => ZodBigInt.create({ ...arg, coerce: true }),
  date: (arg) => ZodDate.create({ ...arg, coerce: true })
};
var NEVER = INVALID;
var z = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: errorMap,
  setErrorMap,
  getErrorMap,
  makeIssue,
  EMPTY_PATH,
  addIssueToContext,
  ParseStatus,
  INVALID,
  DIRTY,
  OK,
  isAborted,
  isDirty,
  isValid,
  isAsync,
  get util() {
    return util;
  },
  get objectUtil() {
    return objectUtil;
  },
  ZodParsedType,
  getParsedType,
  ZodType,
  ZodString,
  ZodNumber,
  ZodBigInt,
  ZodBoolean,
  ZodDate,
  ZodSymbol,
  ZodUndefined,
  ZodNull,
  ZodAny,
  ZodUnknown,
  ZodNever,
  ZodVoid,
  ZodArray,
  ZodObject,
  ZodUnion,
  ZodDiscriminatedUnion,
  ZodIntersection,
  ZodTuple,
  ZodRecord,
  ZodMap,
  ZodSet,
  ZodFunction,
  ZodLazy,
  ZodLiteral,
  ZodEnum,
  ZodNativeEnum,
  ZodPromise,
  ZodEffects,
  ZodTransformer: ZodEffects,
  ZodOptional,
  ZodNullable,
  ZodDefault,
  ZodCatch,
  ZodNaN,
  BRAND,
  ZodBranded,
  ZodPipeline,
  ZodReadonly,
  custom,
  Schema: ZodType,
  ZodSchema: ZodType,
  late,
  get ZodFirstPartyTypeKind() {
    return ZodFirstPartyTypeKind;
  },
  coerce,
  any: anyType,
  array: arrayType,
  bigint: bigIntType,
  boolean: booleanType,
  date: dateType,
  discriminatedUnion: discriminatedUnionType,
  effect: effectsType,
  "enum": enumType,
  "function": functionType,
  "instanceof": instanceOfType,
  intersection: intersectionType,
  lazy: lazyType,
  literal: literalType,
  map: mapType,
  nan: nanType,
  nativeEnum: nativeEnumType,
  never: neverType,
  "null": nullType,
  nullable: nullableType,
  number: numberType,
  object: objectType,
  oboolean,
  onumber,
  optional: optionalType,
  ostring,
  pipeline: pipelineType,
  preprocess: preprocessType,
  promise: promiseType,
  record: recordType,
  set: setType,
  strictObject: strictObjectType,
  string: stringType,
  symbol: symbolType,
  transformer: effectsType,
  tuple: tupleType,
  "undefined": undefinedType,
  union: unionType,
  unknown: unknownType,
  "void": voidType,
  NEVER,
  ZodIssueCode,
  quotelessJson,
  ZodError
});

// src/Datasources.ts
var Datasources_exports = {};
__export(Datasources_exports, {
  AllEventDataPointNameSchema: () => AllEventDataPointNameSchema,
  DataTypeSchema: () => DataTypeSchema,
  SourceTypeSchema: () => SourceTypeSchema,
  TextElementDataPoints: () => TextElementDataPoints,
  combinedDP: () => combinedDP,
  competitionWinnerDataPoints: () => competitionWinnerDataPoints,
  competitionWinnerPointNames: () => competitionWinnerPointNames,
  competitorFocusedEventDataPointNames: () => competitorFocusedEventDataPointNames,
  competitorFocusedEventDataPoints: () => competitorFocusedEventDataPoints,
  createDataSourceDefinitions: () => createDataSourceDefinitions,
  dataSourcesConfigs: () => dataSourcesConfigs,
  dataTypes: () => dataTypes,
  eventDataPointNames: () => eventDataPointNames,
  eventDataPoints: () => eventDataPoints,
  eventDataSourceDefinition: () => eventDataSourceDefinition,
  friendlyDataPointName: () => friendlyDataPointName,
  fullTimeResultDataPointNames: () => fullTimeResultDataPointNames,
  fullTimeResultDataPoints: () => fullTimeResultDataPoints,
  genericTemplateDataPointNames: () => genericTemplateDataPointNames,
  genericTemplateDataPoints: () => genericTemplateDataPoints,
  getDataTypeFromSourceType: () => getDataTypeFromSourceType,
  getFriendlySourceNameFromSourceType: () => getFriendlySourceNameFromSourceType,
  getIconNameForSourceType: () => getIconNameForSourceType,
  handicapDataPointNames: () => handicapDataPointNames,
  handicapDataPoints: () => handicapDataPoints,
  isCompetitorFocusedPoint: () => isCompetitorFocusedPoint,
  isMarketDataType: () => isMarketDataType,
  matchWinnerDataPointNames: () => matchWinnerDataPointNames,
  matchWinnerDataPoints: () => matchWinnerDataPoints,
  newEventDataPointNames: () => newEventDataPointNames,
  newEventDataPoints: () => newEventDataPoints,
  newSourceTypes: () => newSourceTypes,
  outrightDataPointNames: () => outrightDataPointNames,
  outrightDataPoints: () => outrightDataPoints,
  overUnderDataPointNames: () => overUnderDataPointNames,
  overUnderDataPoints: () => overUnderDataPoints,
  parseDataPointName: () => parseDataPointName,
  promoDataPointNames: () => promoDataPointNames,
  promoDataPoints: () => promoDataPoints,
  sameGameParlayDataPointNames: () => sameGameParlayDataPointNames,
  sameGameParlayDataPoints: () => sameGameParlayDataPoints,
  sourceTypeToNormalisedMarketName: () => sourceTypeToNormalisedMarketName,
  sourceTypes: () => sourceTypes,
  suggestDataSources: () => suggestDataSources,
  teamImagesMappingConfigs: () => teamImagesMappingConfigs
});

// ../../node_modules/nanoid/non-secure/index.js
var urlAlphabet = "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict";
var nanoid = (size = 21) => {
  let id = "";
  let i = size;
  while (i--) {
    id += urlAlphabet[Math.random() * 64 | 0];
  }
  return id;
};

// src/Datasources.ts
var sourceTypes = [
  /* old types */
  "EVENT",
  "UNKNOWN_FUTURE_ADDITION",
  /* new types (template) */
  "NEW_EVENT",
  "EVENT_COMPETITOR_FOCUSED",
  "OUTRIGHT",
  /* new types (market) */
  "MATCH_WINNER",
  "FULL_TIME_RESULT",
  "PROMO",
  "OVER_UNDER",
  "HANDICAP",
  "COMPETITION_WINNER",
  "SAME_GAME_PARLAY"
];
var SourceTypeSchema = z.enum(sourceTypes);
var dataTypes = ["TEMPLATE", "MARKET", "UNKNOWN"];
var DataTypeSchema = z.enum(dataTypes);
var newSourceTypes = sourceTypes.filter(
  (type) => type !== "EVENT" && type !== "UNKNOWN_FUTURE_ADDITION"
);
var newEventDataPointNames = [
  "HOME_COLOR_PRIMARY",
  "HOME_COLOR_SECONDARY",
  "AWAY_COLOR_PRIMARY",
  "AWAY_COLOR_SECONDARY"
];
var competitorFocusedEventDataPointNames = [
  "COMPETITOR_NAME",
  "OPPONENT_NAME",
  "COMPETITOR_MICRO_NAME",
  "OPPONENT_MICRO_NAME",
  "FOCUS_COMPETITOR_ID",
  "OPPONENT_COMPETITOR_ID",
  "FOCUS_COMPETITOR_COUNTRY_CODE",
  "OPPONENT_COMPETITOR_COUNTRY_CODE"
];
var genericTemplateDataPointNames = [
  "DAY",
  "DATE",
  "MONTH",
  "TIME",
  "SPORT_NAME",
  "SPORT_ID",
  "COMPETITOR_ID",
  "HOME_COMPETITOR_ID",
  "AWAY_COMPETITOR_ID",
  "HOME_COMPETITOR_COUNTRY_CODE",
  "AWAY_COMPETITOR_COUNTRY_CODE",
  "COMPETITION_ID",
  "COMPETITION_NAME",
  "EVENT_NAME",
  "HOME_NAME",
  "AWAY_NAME",
  "HOME_NAME_MICRO",
  "AWAY_NAME_MICRO",
  "VENUE",
  "VENUE_ID",
  "CITY_NAME",
  "MAIN_VIDEO",
  "STAKE"
];
var outrightDataPointNames = [
  "SPORT_NAME",
  "COMPETITION_NAME",
  "OUTRIGHT_NAME"
];
var overUnderDataPointNames = [
  "MARKET_NAME",
  "OVER_ODDS",
  "UNDER_ODDS",
  "TOTAL",
  "INSIGHT_TEXT"
];
var handicapDataPointNames = [
  "MARKET_NAME",
  "AWAY_ODDS",
  "HOME_ODDS",
  "HOME_SPREAD",
  "AWAY_SPREAD",
  "INSIGHT_TEXT"
];
var matchWinnerDataPointNames = [
  "MARKET_NAME",
  "HOME_ODDS",
  "AWAY_ODDS",
  "COMPETITOR_ODDS",
  "OPPONENT_ODDS",
  "INSIGHT_TEXT"
];
var fullTimeResultDataPointNames = [
  "MARKET_NAME",
  "HOME_ODDS",
  "AWAY_ODDS",
  "DRAW_ODDS",
  "INSIGHT_TEXT"
];
var promoDataPointNames = [
  "MARKET_NAME",
  "PROMO_ODDS",
  "PROMO_WAS_ODDS",
  "PROMO_TEXT"
];
var competitionWinnerPointNames = [
  "MARKET_NAME",
  "OPTION_1_NAME",
  "OPTION_1_ODDS",
  "OPTION_1_COMPETITOR_ID",
  "OPTION_2_NAME",
  "OPTION_2_ODDS",
  "OPTION_2_COMPETITOR_ID",
  "OPTION_3_NAME",
  "OPTION_3_ODDS",
  "OPTION_3_COMPETITOR_ID",
  "OPTION_4_NAME",
  "OPTION_4_ODDS",
  "OPTION_4_COMPETITOR_ID",
  "OPTION_5_NAME",
  "OPTION_5_ODDS",
  "OPTION_5_COMPETITOR_ID",
  "OPTION_6_NAME",
  "OPTION_6_ODDS",
  "OPTION_6_COMPETITOR_ID",
  "OPTION_7_NAME",
  "OPTION_7_ODDS",
  "OPTION_7_COMPETITOR_ID",
  "OPTION_8_NAME",
  "OPTION_8_ODDS",
  "OPTION_8_COMPETITOR_ID",
  "OPTION_9_NAME",
  "OPTION_9_ODDS",
  "OPTION_9_COMPETITOR_ID",
  "OPTION_10_NAME",
  "OPTION_10_ODDS",
  "OPTION_10_COMPETITOR_ID"
];
var sameGameParlayDataPointNames = [
  "MARKET_NAME",
  "ODDS",
  "WAS_ODDS",
  "OPTION_1_NAME",
  "OPTION_1_OUTCOME_NAME",
  "OPTION_1_MARKET_NAME",
  "OPTION_2_NAME",
  "OPTION_2_OUTCOME_NAME",
  "OPTION_2_MARKET_NAME",
  "OPTION_3_NAME",
  "OPTION_3_OUTCOME_NAME",
  "OPTION_3_MARKET_NAME",
  "OPTION_4_NAME",
  "OPTION_4_OUTCOME_NAME",
  "OPTION_4_MARKET_NAME",
  "OPTION_5_NAME",
  "OPTION_5_OUTCOME_NAME",
  "OPTION_5_MARKET_NAME"
];
var eventDataPointNames = [
  "EVENT_NAME",
  "HOME_NAME",
  "AWAY_NAME",
  "HOME_NAME_MICRO",
  "AWAY_NAME_MICRO",
  "MARKET_NAME",
  "HOME_ODDS",
  "AWAY_ODDS",
  "DRAW_ODDS",
  "COMPETITION_NAME",
  "DATE",
  "TIME",
  "COMPETITOR_ID",
  "PROMO_ODDS",
  "PROMO_WAS_ODDS",
  "PROMO_TEXT",
  "SPORT"
];
var eventDataPoints = [
  { name: "EVENT_NAME", type: "TEXT" },
  { name: "HOME_NAME", type: "TEXT" },
  { name: "AWAY_NAME", type: "TEXT" },
  { name: "HOME_NAME_MICRO", type: "TEXT" },
  { name: "AWAY_NAME_MICRO", type: "TEXT" },
  { name: "MARKET_NAME", type: "TEXT" },
  { name: "HOME_ODDS", type: "TEXT" },
  { name: "AWAY_ODDS", type: "TEXT" },
  { name: "DRAW_ODDS", type: "TEXT" },
  { name: "COMPETITION_NAME", type: "TEXT" },
  { name: "TIME", type: "TEXT" },
  { name: "DATE", type: "TEXT" },
  { name: "PROMO_ODDS", type: "TEXT" },
  { name: "PROMO_WAS_ODDS", type: "TEXT" },
  { name: "PROMO_TEXT", type: "TEXT" },
  { name: "SPORT", type: "TEXT" },
  { name: "COMPETITOR_ID", type: "TEAM_ASSET" }
];
var newEventDataPoints = [
  {
    name: "HOME_COLOR_PRIMARY",
    autoImportName: "HOME_COLOR_PRIMARY",
    type: "TEXT"
  },
  {
    name: "HOME_COLOR_SECONDARY",
    autoImportName: "HOME_COLOR_SECONDARY",
    type: "TEXT"
  },
  {
    name: "AWAY_COLOR_PRIMARY",
    autoImportName: "AWAY_COLOR_PRIMARY",
    type: "TEXT"
  },
  {
    name: "AWAY_COLOR_SECONDARY",
    autoImportName: "AWAY_COLOR_SECONDARY",
    type: "TEXT"
  }
];
var competitorFocusedEventDataPoints = [
  {
    name: "COMPETITOR_NAME",
    autoImportName: "COMPETITOR_NAME",
    type: "TEXT"
  },
  { name: "OPPONENT_NAME", autoImportName: "OPPONENT_NAME", type: "TEXT" },
  {
    name: "COMPETITOR_MICRO_NAME",
    autoImportName: "COMPETITOR_MICRO_NAME",
    type: "TEXT"
  },
  {
    name: "OPPONENT_MICRO_NAME",
    autoImportName: "OPPONENT_MICRO_NAME",
    type: "TEXT"
  },
  {
    name: "FOCUS_COMPETITOR_ID",
    type: "IMAGE"
  },
  {
    name: "OPPONENT_COMPETITOR_ID",
    type: "IMAGE"
  },
  {
    name: "FOCUS_COMPETITOR_COUNTRY_CODE",
    type: "IMAGE"
  },
  {
    name: "OPPONENT_COMPETITOR_COUNTRY_CODE",
    type: "IMAGE"
  }
];
var genericTemplateDataPoints = [
  { name: "DAY", type: "TEXT", autoImportName: "DAY" },
  { name: "DATE", type: "TEXT", autoImportName: "DATE" },
  { name: "MONTH", type: "TEXT", autoImportName: "MONTH" },
  { name: "TIME", type: "TEXT", autoImportName: "TIME" },
  { name: "SPORT_NAME", type: "TEXT", autoImportName: "SPORT_NAME" },
  {
    name: "COMPETITION_NAME",
    type: "TEXT",
    autoImportName: "COMPETITION_NAME"
  },
  { name: "EVENT_NAME", type: "TEXT", autoImportName: "EVENT_NAME" },
  { name: "HOME_NAME", type: "TEXT", autoImportName: "HOME_NAME" },
  { name: "AWAY_NAME", type: "TEXT", autoImportName: "AWAY_NAME" },
  { name: "HOME_NAME_MICRO", type: "TEXT", autoImportName: "HOME_NAME_MICRO" },
  { name: "AWAY_NAME_MICRO", type: "TEXT", autoImportName: "AWAY_NAME_MICRO" },
  { name: "COMPETITOR_ID", type: "TEAM_ASSET" },
  { name: "HOME_COMPETITOR_ID", type: "IMAGE" },
  { name: "AWAY_COMPETITOR_ID", type: "IMAGE" },
  { name: "COMPETITION_ID", type: "IMAGE" },
  { name: "VENUE", type: "TEXT", autoImportName: "VENUE" },
  { name: "CITY_NAME", type: "TEXT", autoImportName: "CITY_NAME" },
  { name: "VENUE_ID", type: "IMAGE" },
  { name: "SPORT_ID", type: "IMAGE" },
  { name: "HOME_COMPETITOR_COUNTRY_CODE", type: "IMAGE" },
  { name: "AWAY_COMPETITOR_COUNTRY_CODE", type: "IMAGE" },
  { name: "MAIN_VIDEO", type: "IMAGE" },
  { name: "STAKE", type: "TEXT", autoImportName: "STAKE" }
];
var outrightDataPoints = [
  { name: "SPORT_NAME", type: "TEXT", autoImportName: "SPORT_NAME-OR" },
  {
    name: "COMPETITION_NAME",
    type: "TEXT",
    autoImportName: "COMPETITION_NAME-OR"
  },
  { name: "OUTRIGHT_NAME", type: "TEXT", autoImportName: "OUTRIGHT_NAME-OR" }
];
var competitionWinnerDataPoints = [
  { name: "SPORT_ID", type: "IMAGE" },
  { name: "COMPETITION_ID", type: "IMAGE" },
  { name: "MARKET_NAME", type: "TEXT", autoImportName: "MARKET_NAME-CW" },
  { name: "OPTION_1_NAME", type: "TEXT", autoImportName: "OPTION_1_NAME-CW" },
  { name: "OPTION_1_ODDS", type: "TEXT", autoImportName: "OPTION_1_ODDS-CW" },
  { name: "OPTION_1_COMPETITOR_ID", type: "IMAGE" },
  { name: "OPTION_2_NAME", type: "TEXT", autoImportName: "OPTION_2_NAME-CW" },
  { name: "OPTION_2_ODDS", type: "TEXT", autoImportName: "OPTION_2_ODDS-CW" },
  { name: "OPTION_2_COMPETITOR_ID", type: "IMAGE" },
  { name: "OPTION_3_NAME", type: "TEXT", autoImportName: "OPTION_3_NAME-CW" },
  { name: "OPTION_3_ODDS", type: "TEXT", autoImportName: "OPTION_3_ODDS-CW" },
  { name: "OPTION_3_COMPETITOR_ID", type: "IMAGE" },
  { name: "OPTION_4_NAME", type: "TEXT", autoImportName: "OPTION_4_NAME-CW" },
  { name: "OPTION_4_ODDS", type: "TEXT", autoImportName: "OPTION_4_ODDS-CW" },
  { name: "OPTION_4_COMPETITOR_ID", type: "IMAGE" },
  { name: "OPTION_5_NAME", type: "TEXT", autoImportName: "OPTION_5_NAME-CW" },
  { name: "OPTION_5_ODDS", type: "TEXT", autoImportName: "OPTION_5_ODDS-CW" },
  { name: "OPTION_5_COMPETITOR_ID", type: "IMAGE" },
  { name: "OPTION_6_NAME", type: "TEXT", autoImportName: "OPTION_6_NAME-CW" },
  { name: "OPTION_6_ODDS", type: "TEXT", autoImportName: "OPTION_6_ODDS-CW" },
  { name: "OPTION_6_COMPETITOR_ID", type: "IMAGE" },
  { name: "OPTION_7_NAME", type: "TEXT", autoImportName: "OPTION_7_NAME-CW" },
  { name: "OPTION_7_ODDS", type: "TEXT", autoImportName: "OPTION_7_ODDS-CW" },
  { name: "OPTION_7_COMPETITOR_ID", type: "IMAGE" },
  { name: "OPTION_8_NAME", type: "TEXT", autoImportName: "OPTION_8_NAME-CW" },
  { name: "OPTION_8_ODDS", type: "TEXT", autoImportName: "OPTION_8_ODDS-CW" },
  { name: "OPTION_8_COMPETITOR_ID", type: "IMAGE" },
  { name: "OPTION_9_NAME", type: "TEXT", autoImportName: "OPTION_9_NAME-CW" },
  { name: "OPTION_9_ODDS", type: "TEXT", autoImportName: "OPTION_9_ODDS-CW" },
  { name: "OPTION_9_COMPETITOR_ID", type: "IMAGE" },
  { name: "OPTION_10_NAME", type: "TEXT", autoImportName: "OPTION_10_NAME-CW" },
  { name: "OPTION_10_ODDS", type: "TEXT", autoImportName: "OPTION_10_ODDS-CW" },
  { name: "OPTION_10_COMPETITOR_ID", type: "IMAGE" }
];
var sameGameParlayDataPoints = [
  {
    name: "MARKET_NAME",
    type: "TEXT",
    autoImportName: "MARKET_NAME-SGP"
  },
  {
    name: "ODDS",
    type: "TEXT",
    autoImportName: "ODDS-SGP"
  },
  {
    name: "WAS_ODDS",
    type: "TEXT",
    autoImportName: "WAS_ODDS-SGP"
  },
  {
    name: "OPTION_1_NAME",
    type: "TEXT",
    autoImportName: "OPTION_1_NAME-SGP"
  },
  {
    name: "OPTION_1_OUTCOME_NAME",
    type: "TEXT",
    autoImportName: "OPTION_1_OUTCOME_NAME-SGP"
  },
  {
    name: "OPTION_1_MARKET_NAME",
    type: "TEXT",
    autoImportName: "OPTION_1_MARKET_NAME-SGP"
  },
  {
    name: "OPTION_2_NAME",
    type: "TEXT",
    autoImportName: "OPTION_2_NAME-SGP"
  },
  {
    name: "OPTION_2_OUTCOME_NAME",
    type: "TEXT",
    autoImportName: "OPTION_2_OUTCOME_NAME-SGP"
  },
  {
    name: "OPTION_2_MARKET_NAME",
    type: "TEXT",
    autoImportName: "OPTION_2_MARKET_NAME-SGP"
  },
  {
    name: "OPTION_3_NAME",
    type: "TEXT",
    autoImportName: "OPTION_3_NAME-SGP"
  },
  {
    name: "OPTION_3_OUTCOME_NAME",
    type: "TEXT",
    autoImportName: "OPTION_3_OUTCOME_NAME-SGP"
  },
  {
    name: "OPTION_3_MARKET_NAME",
    type: "TEXT",
    autoImportName: "OPTION_3_MARKET_NAME-SGP"
  },
  {
    name: "OPTION_4_NAME",
    type: "TEXT",
    autoImportName: "OPTION_4_NAME-SGP"
  },
  {
    name: "OPTION_4_OUTCOME_NAME",
    type: "TEXT",
    autoImportName: "OPTION_4_OUTCOME_NAME-SGP"
  },
  {
    name: "OPTION_4_MARKET_NAME",
    type: "TEXT",
    autoImportName: "OPTION_4_MARKET_NAME-SGP"
  },
  {
    name: "OPTION_5_NAME",
    type: "TEXT",
    autoImportName: "OPTION_5_NAME-SGP"
  },
  {
    name: "OPTION_5_OUTCOME_NAME",
    type: "TEXT",
    autoImportName: "OPTION_5_OUTCOME_NAME-SGP"
  },
  {
    name: "OPTION_5_MARKET_NAME",
    type: "TEXT",
    autoImportName: "OPTION_5_MARKET_NAME-SGP"
  }
];
var matchWinnerDataPoints = [
  { name: "MARKET_NAME", type: "TEXT", autoImportName: "MARKET_NAME-MW" },
  { name: "HOME_ODDS", type: "TEXT", autoImportName: "HOME_ODDS-MW" },
  { name: "AWAY_ODDS", type: "TEXT", autoImportName: "AWAY_ODDS-MW" },
  {
    name: "COMPETITOR_ODDS",
    autoImportName: "COMPETITOR_ODDS-MW",
    type: "TEXT"
  },
  { name: "OPPONENT_ODDS", autoImportName: "OPPONENT_ODDS-MW", type: "TEXT" },
  { name: "INSIGHT_TEXT", autoImportName: "INSIGHT_TEXT-MW", type: "TEXT" }
];
var fullTimeResultDataPoints = [
  { name: "MARKET_NAME", type: "TEXT", autoImportName: "MARKET_NAME-FTR" },
  { name: "HOME_ODDS", type: "TEXT", autoImportName: "HOME_ODDS-FTR" },
  { name: "AWAY_ODDS", type: "TEXT", autoImportName: "AWAY_ODDS-FTR" },
  { name: "DRAW_ODDS", type: "TEXT", autoImportName: "DRAW_ODDS-FTR" },
  {
    name: "COMPETITOR_ODDS",
    autoImportName: "COMPETITOR_ODDS-FTR",
    type: "TEXT"
  },
  { name: "OPPONENT_ODDS", autoImportName: "OPPONENT_ODDS-FTR", type: "TEXT" },
  { name: "INSIGHT_TEXT", type: "TEXT", autoImportName: "INSIGHT_TEXT-FTR" }
];
var promoDataPoints = [
  { name: "MARKET_NAME", type: "TEXT", autoImportName: "MARKET_NAME-PR" },
  { name: "PROMO_ODDS", type: "TEXT", autoImportName: "PROMO_ODDS-PR" },
  { name: "PROMO_WAS_ODDS", type: "TEXT", autoImportName: "PROMO_WAS_ODDS-PR" },
  { name: "PROMO_TEXT", type: "TEXT", autoImportName: "PROMO_TEXT-PR" }
];
var overUnderDataPoints = [
  { name: "MARKET_NAME", autoImportName: "MARKET_NAME-OU", type: "TEXT" },
  { name: "OVER_ODDS", autoImportName: "OVER_ODDS-OU", type: "TEXT" },
  { name: "UNDER_ODDS", autoImportName: "UNDER_ODDS-OU", type: "TEXT" },
  { name: "TOTAL", autoImportName: "TOTAL-OU", type: "TEXT" },
  { name: "INSIGHT_TEXT", autoImportName: "INSIGHT_TEXT-OU", type: "TEXT" }
];
var handicapDataPoints = [
  { name: "MARKET_NAME", autoImportName: "MARKET_NAME-HC", type: "TEXT" },
  { name: "HOME_ODDS", autoImportName: "HOME_ODDS-HC", type: "TEXT" },
  { name: "AWAY_ODDS", autoImportName: "AWAY_ODDS-HC", type: "TEXT" },
  { name: "HOME_SPREAD", autoImportName: "HOME_SPREAD-HC", type: "TEXT" },
  { name: "AWAY_SPREAD", autoImportName: "AWAY_SPREAD-HC", type: "TEXT" },
  { name: "INSIGHT_TEXT", autoImportName: "INSIGHT_TEXT-HC", type: "TEXT" }
];
var TextElementDataPoints = eventDataPoints.filter((dp) => dp.type === "TEXT").map((dp) => dp.name);
var combinedDP = [
  ...newEventDataPointNames,
  ...competitorFocusedEventDataPointNames,
  ...genericTemplateDataPointNames,
  ...matchWinnerDataPointNames,
  ...fullTimeResultDataPointNames,
  ...promoDataPointNames,
  ...overUnderDataPointNames,
  ...handicapDataPointNames,
  ...eventDataPointNames,
  ...outrightDataPointNames,
  ...competitionWinnerPointNames,
  ...sameGameParlayDataPointNames
];
var AllEventDataPointNameSchema = z.enum(combinedDP);
var DataSource = class {
  name;
  id;
  dataType;
  type;
  dataPoints;
  constructor(name, dataPoints, dataType, type, id = null) {
    this.name = name;
    this.dataPoints = dataPoints;
    this.dataType = dataType;
    this.type = type;
    this.id = id ?? nanoid(11);
  }
  get shortenedType() {
    const typeMap = {
      MATCH_WINNER: "MW",
      FULL_TIME_RESULT: "FTR",
      PROMO: "PR",
      OVER_UNDER: "OU",
      HANDICAP: "HC",
      EVENT_COMPETITOR_FOCUSED: "",
      COMPETITION_WINNER: "CW",
      SAME_GAME_PARLAY: "SGP",
      OUTRIGHT: "OR",
      NEW_EVENT: "",
      EVENT: "",
      UNKNOWN_FUTURE_ADDITION: ""
    };
    const shortenedValue = typeMap[this.type];
    return shortenedValue;
  }
  getSuggestions(type) {
    return this.dataPoints.filter((dp) => dp.type === type).map((point) => ({
      id: `${this.id}:${point.name}`,
      display: friendlyDataPointName(point.name),
      sourceName: this.name,
      sourceTypeShort: this.shortenedType
    }));
  }
};
var eventDataSourceDefinition = new DataSource(
  "Event",
  eventDataPoints,
  "TEMPLATE",
  "EVENT"
);
var isMarketDataType = (type) => {
  return type === "MATCH_WINNER" || type === "FULL_TIME_RESULT" || type === "PROMO" || type === "OVER_UNDER" || type === "HANDICAP" || type === "COMPETITION_WINNER" || type === "SAME_GAME_PARLAY";
};
var sourceTypeToNormalisedMarketName = (source) => {
  switch (source) {
    case "MATCH_WINNER":
      return "Match Winner";
    case "FULL_TIME_RESULT":
      return "Full-Time Result";
    case "PROMO":
      return "Promo";
    case "OVER_UNDER":
      return "Over/Under Totals";
    case "HANDICAP":
      return "Handicap Betting";
    case "COMPETITION_WINNER":
      return "Competition Winner";
    case "SAME_GAME_PARLAY":
      return "Same Game Parlay";
    default:
      throw new Error(
        `sourceTypeToNormalisedMarketName() - unknown source type - ${source}`
      );
  }
};
var dataSourcesConfigs = [
  {
    name: "Event",
    points: eventDataPoints,
    dataType: "TEMPLATE",
    type: "EVENT"
  },
  {
    name: "Event",
    points: [...newEventDataPoints, ...genericTemplateDataPoints],
    dataType: "TEMPLATE",
    type: "NEW_EVENT"
  },
  {
    name: "Event - Competitor Focused",
    points: [...competitorFocusedEventDataPoints, ...genericTemplateDataPoints],
    dataType: "TEMPLATE",
    type: "EVENT_COMPETITOR_FOCUSED"
  },
  {
    name: "Outright",
    points: outrightDataPoints,
    dataType: "TEMPLATE",
    type: "OUTRIGHT"
  },
  {
    name: "Match Winner",
    points: matchWinnerDataPoints,
    dataType: "MARKET",
    type: "MATCH_WINNER"
  },
  {
    name: "Full Time Result",
    points: fullTimeResultDataPoints,
    dataType: "MARKET",
    type: "FULL_TIME_RESULT"
  },
  {
    name: "Promo",
    points: promoDataPoints,
    dataType: "MARKET",
    type: "PROMO"
  },
  {
    name: "Handicap",
    points: handicapDataPoints,
    dataType: "MARKET",
    type: "HANDICAP"
  },
  {
    name: "Over/Under",
    points: overUnderDataPoints,
    dataType: "MARKET",
    type: "OVER_UNDER"
  },
  {
    name: "Competition Winner",
    points: competitionWinnerDataPoints,
    dataType: "MARKET",
    type: "COMPETITION_WINNER"
  },
  {
    name: "Same Game Parlay",
    points: sameGameParlayDataPoints,
    dataType: "MARKET",
    type: "SAME_GAME_PARLAY"
  }
];
var teamImagesMappingConfigs = [
  {
    name: "HOME_BADGE",
    autoImportName: "HOME_BADGE",
    dataType: "TEMPLATE",
    type: "NEW_EVENT"
  },
  {
    name: "HOME_JERSEY",
    autoImportName: "HOME_JERSEY",
    dataType: "TEMPLATE",
    type: "NEW_EVENT"
  },
  {
    name: "AWAY_BADGE",
    autoImportName: "AWAY_BADGE",
    dataType: "TEMPLATE",
    type: "NEW_EVENT"
  },
  {
    name: "AWAY_JERSEY",
    autoImportName: "AWAY_JERSEY",
    dataType: "TEMPLATE",
    type: "NEW_EVENT"
  },
  {
    name: "COMPETITOR_BADGE",
    autoImportName: "COMPETITOR_BADGE",
    dataType: "TEMPLATE",
    type: "EVENT_COMPETITOR_FOCUSED"
  },
  {
    name: "COMPETITOR_JERSEY",
    autoImportName: "COMPETITOR_JERSEY",
    dataType: "TEMPLATE",
    type: "EVENT_COMPETITOR_FOCUSED"
  },
  {
    name: "OPPONENT_BADGE",
    autoImportName: "OPPONENT_BADGE",
    dataType: "TEMPLATE",
    type: "EVENT_COMPETITOR_FOCUSED"
  },
  {
    name: "OPPONENT_JERSEY",
    autoImportName: "OPPONENT_JERSEY",
    dataType: "TEMPLATE",
    type: "EVENT_COMPETITOR_FOCUSED"
  }
];
var createDataSourceDefinitions = (adTemplateDataSources) => {
  return dataSourcesConfigs.map((dsc) => {
    const id = adTemplateDataSources?.find((ds) => ds.type === dsc.type)?.id || null;
    return new DataSource(dsc.name, dsc.points, dsc.dataType, dsc.type, id);
  });
};
var suggestDataSources = (inputElements) => {
  const isCompetitorFocused = inputElements.some(
    (te) => isCompetitorFocusedPoint(te.name)
  );
  const mappedDataSources = inputElements.flatMap((te) => {
    return dataSourcesConfigs.filter(
      (config) => !(isCompetitorFocused && config.dataType === "TEMPLATE" && config.type !== "EVENT_COMPETITOR_FOCUSED")
    ).flatMap((config) => {
      return config.points.filter(
        (dp) => dp.autoImportName != null && te.name === dp.autoImportName
      ).map((dp) => {
        return {
          elId: te.id,
          dataPointName: dp.name,
          type: config.type,
          dataType: config.dataType,
          name: config.name
        };
      });
    });
  });
  const teamImagesDataSources = inputElements.flatMap((te) => {
    return teamImagesMappingConfigs.filter((config) => config.autoImportName === te.name).map((config) => {
      return {
        elId: te.id,
        dataPointName: config.name,
        type: config.type,
        dataType: config.dataType,
        name: config.name
      };
    });
  });
  const flat = [...mappedDataSources, ...teamImagesDataSources];
  const result = /* @__PURE__ */ new Set([
    ...flat.filter((item) => item.dataType === "TEMPLATE").map((v) => v.type)
  ]);
  let filtered = flat;
  if (result.size > 1) {
    filtered = flat.filter((item) => {
      return item.dataType === "TEMPLATE" ? item.type === "NEW_EVENT" : true;
    });
  }
  const byType = filtered.reduce((acc, curr) => {
    if (!acc[curr.type]) {
      acc[curr.type] = [];
    }
    acc[curr.type].push({
      elId: curr.elId,
      dataPointName: curr.dataPointName,
      type: curr.type,
      name: curr.name
    });
    return acc;
  }, {});
  return Object.values(byType).filter((v) => v.length > 0).sort((a, b) => a[0].type.localeCompare(b[0].type));
};
var cfPoints = [
  ...competitorFocusedEventDataPointNames,
  ...[
    "OPPONENT_ODDS-",
    "COMPETITOR_ODDS-",
    "OPPONENT_JERSEY",
    "COMPETITOR_JERSEY",
    "OPPONENT_BADGE",
    "COMPETITOR_BADGE"
  ]
  // these are used by markets
];
var isCompetitorFocusedPoint = (point) => {
  return cfPoints.some((cfPoint) => point.startsWith(cfPoint));
};
var parseDataPointName = (maybeDataPointName, allowableDataPointNames) => {
  const dataPointName = allowableDataPointNames.find(
    (dp) => dp === maybeDataPointName
  );
  if (dataPointName) {
    return dataPointName;
  }
  throw new Error("That is not a data point name.");
};
function friendlyDataPointName(str) {
  return str.split("_").map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(" ");
}
function getDataTypeFromSourceType(sourceType) {
  const map = {
    EVENT: "TEMPLATE",
    NEW_EVENT: "TEMPLATE",
    EVENT_COMPETITOR_FOCUSED: "TEMPLATE",
    MATCH_WINNER: "MARKET",
    FULL_TIME_RESULT: "MARKET",
    PROMO: "MARKET",
    OVER_UNDER: "MARKET",
    HANDICAP: "MARKET",
    OUTRIGHT: "TEMPLATE",
    COMPETITION_WINNER: "MARKET",
    SAME_GAME_PARLAY: "MARKET",
    UNKNOWN_FUTURE_ADDITION: "UNKNOWN"
  };
  return map[sourceType];
}
function getFriendlySourceNameFromSourceType(sourceType) {
  const map = {
    EVENT: "Event",
    NEW_EVENT: "Event",
    OUTRIGHT: "Outright",
    EVENT_COMPETITOR_FOCUSED: "Event - Competitor Focused",
    MATCH_WINNER: "Match Winner",
    FULL_TIME_RESULT: "Full Time Result",
    PROMO: "Promo",
    OVER_UNDER: "Over/Under",
    HANDICAP: "Handicap",
    COMPETITION_WINNER: "Competition Winner",
    SAME_GAME_PARLAY: "Same Game Parlay",
    UNKNOWN_FUTURE_ADDITION: "Unknown Future Addition"
  };
  return map[sourceType];
}
function getIconNameForSourceType(sourceType) {
  const iconMap = {
    EVENT: "LuCalendarClock",
    MATCH_WINNER: "LuTarget",
    UNKNOWN_FUTURE_ADDITION: "LuXCircle",
    FULL_TIME_RESULT: "LuTimer",
    PROMO: "LuPercent",
    NEW_EVENT: "LuCalendarClock",
    OVER_UNDER: "LuTarget",
    HANDICAP: "LuTarget",
    OUTRIGHT: "LuTarget",
    COMPETITION_WINNER: "LuTarget",
    SAME_GAME_PARLAY: "LuTarget",
    EVENT_COMPETITOR_FOCUSED: "LuDribbble"
  };
  return iconMap[sourceType] ?? "LuXCircle";
}

// src/utils.ts
function discriminate(discriminantKey, discriminantValue) {
  return (obj) => obj[discriminantKey] === discriminantValue;
}
function notEmpty(value) {
  return value !== null && value !== void 0;
}
function stringIsOfUnionType(str, arr) {
  return arr.find((arrItem) => arrItem === str) !== void 0;
}

// src/TeamAssetElement.ts
var TeamAssetElement_exports = {};
__export(TeamAssetElement_exports, {
  SportKinds: () => SportKinds,
  headGearColourSchema: () => headGearColourSchema,
  isBadgeData: () => isBadgeData,
  isKitData: () => isKitData,
  jerseyColourSchema: () => jerseyColourSchema,
  kitSchema: () => kitSchema
});
var jerseyColourSchema = z.object({
  background: z.string(),
  base: z.string().optional(),
  number: z.string().optional(),
  outline: z.string().optional(),
  sleeve: z.string().optional(),
  shirtType: z.string().optional(),
  squares: z.string().optional(),
  horizontalStripe: z.string().optional(),
  verticalStripe: z.string().optional(),
  split: z.string().optional(),
  chest: z.string().optional(),
  neck: z.string().optional()
});
var headGearColourSchema = z.object({
  background: z.string(),
  accent: z.string().optional()
});
var kitSchema = z.union([
  z.object({
    kind: z.literal("shortSleeveJersey"),
    colours: jerseyColourSchema,
    rotate: z.boolean().optional()
  }),
  z.object({
    kind: z.literal("noSleeveJersey"),
    colours: jerseyColourSchema,
    rotate: z.boolean().optional()
  }),
  z.object({
    kind: z.literal("longSleeveJersey"),
    colours: jerseyColourSchema,
    rotate: z.boolean().optional()
  }),
  z.object({
    kind: z.literal("baseballHat"),
    colours: headGearColourSchema,
    rotate: z.boolean().optional()
  }),
  z.object({
    kind: z.literal("footballHelmet"),
    colours: headGearColourSchema,
    rotate: z.boolean().optional()
  })
]);
var isBadgeData = discriminate("type", "badge");
var isKitData = discriminate("type", "kit");
var SportKinds = /* @__PURE__ */ ((SportKinds2) => {
  SportKinds2["AMERICAN_FOOTBALL_HELMET"] = "footballHelmet";
  SportKinds2["BASEBALL_HAT"] = "baseballHat";
  SportKinds2["ICE_HOCKEY_JERSEY"] = "longSleeveJersey";
  SportKinds2["FOOTBALL_JERSEY"] = "shortSleeveJersey";
  SportKinds2["BASKETBALL_JERSEY"] = "noSleeveJersey";
  SportKinds2["RUGBY_LEAGUE_JERSEY"] = "shortSleeveJersey";
  SportKinds2["RUGBY_UNION_JERSEY"] = "shortSleeveJersey";
  return SportKinds2;
})(SportKinds || {});

// src/AdflowAPI.ts
var svgTypeName = z.literal("SVG");
var textTypeName = z.literal("TEXT");
var imageTypeName = z.literal("IMAGE");
var teamAssetTypeName = z.literal("TEAM_ASSET");
var vectorTypeName = z.literal("VECTOR");
var AdElementTypeSchema = z.union([
  svgTypeName,
  textTypeName,
  imageTypeName,
  teamAssetTypeName,
  vectorTypeName
]);
var AdElementDataSourceSchema = z.object({
  sourceId: z.string(),
  name: AllEventDataPointNameSchema
});
var AdElementBase = z.object({
  id: z.string(),
  name: z.string(),
  position: z.object({
    x: z.number(),
    y: z.number(),
    z: z.number().default(0)
  }),
  size: z.object({
    height: z.number(),
    width: z.number()
  }),
  rotation: z.number().optional().nullable(),
  sources: z.array(AdElementDataSourceSchema),
  type: z.union([svgTypeName, textTypeName])
});
var AdElementSVGSchema = AdElementBase.extend({
  type: svgTypeName,
  data: z.object({
    svg: z.string()
  })
});
var DateTimeFormatSchema = z.object({
  timeFormat: z.enum(["hour12", "hour24"]).optional().nullable(),
  monthFormat: z.enum(["long", "short"]).optional().nullable(),
  dayFormat: z.enum(["long", "short"]).optional().nullable(),
  showTimezone: z.boolean().default(false).optional().nullable()
});
var AdElementTextSchema = AdElementBase.extend({
  type: textTypeName,
  data: z.object({
    content: z.string(),
    // {HOME_NAME} to beat {AWAY_NAME}
    isDate: z.boolean().optional().nullable(),
    dateFormat: z.string().optional().nullable(),
    fixture: z.string().optional().nullable(),
    dateDisplayOptions: DateTimeFormatSchema.optional().nullable(),
    locale: z.string().optional().nullable(),
    dynamicFont: z.discriminatedUnion("enabled", [
      z.object({
        enabled: z.literal(true),
        maxFontSize: z.number(),
        minFontSize: z.number(),
        lineHeight: z.number(),
        multiLine: z.boolean()
      }),
      z.object({
        enabled: z.literal(false)
      })
    ]),
    style: z.object({
      color: z.string().nullable().optional(),
      stroke: z.string().nullable().optional(),
      strokeWeight: z.number().nullable().optional(),
      fontFamily: z.string().nullable().optional(),
      fontWeight: z.number().nullable().optional(),
      fontStyle: z.string().nullable().optional(),
      fontSize: z.number().nullable().optional(),
      textAlign: z.union([z.literal("left"), z.literal("center"), z.literal("right")]).nullable().optional(),
      textCase: z.union([
        z.literal("none"),
        z.literal("uppercase"),
        z.literal("lowercase"),
        z.literal("capitalize")
      ]).nullable().optional(),
      letterSpacing: z.number().nullable().optional(),
      lineHeight: z.number().positive().nullable().optional(),
      transform: z.string().nullable().optional(),
      textDecoration: z.union([z.literal("underline"), z.literal("line-through")]).nullable().optional(),
      textShadow: z.string().nullable().optional()
    })
  })
});
var AdElementImageSchema = AdElementBase.extend({
  type: imageTypeName,
  data: z.object({
    content: z.string(),
    initialContent: z.string().optional().nullable()
  })
});
var AdElementTeamAssetSchema = AdElementBase.extend({
  type: teamAssetTypeName,
  data: z.object({
    content: z.string(),
    source: z.union([
      z.literal("HOME_ASSET"),
      z.literal("AWAY_ASSET"),
      z.literal("COMPETITOR_ASSET"),
      z.literal("OPPONENT_ASSET")
    ]).optional().nullable(),
    display: z.union([
      z.object({ type: z.literal("kit"), data: kitSchema }),
      z.object({
        type: z.literal("badge"),
        data: z.object({ content: z.string() })
      })
    ]).optional().nullable()
  })
});
var adElementVectorColor = z.object({
  value: z.string(),
  type: z.union([z.literal("PRIMARY"), z.literal("SECONDARY")]).nullable().default(null),
  source: z.union([
    z.literal("HOME_COLOR"),
    z.literal("AWAY_COLOR"),
    z.literal("COMPETITOR_COLOR"),
    z.literal("OPPONENT_COLOR")
  ]).nullable().default(null)
});
var AdElementVectorSchema = AdElementBase.extend({
  type: vectorTypeName,
  data: z.object({
    color: z.object({
      strokeWeight: z.number().nullable().optional(),
      fill: adElementVectorColor.nullable(),
      stroke: adElementVectorColor.nullable()
    }),
    content: z.string(),
    viewBox: z.object({
      height: z.number(),
      width: z.number()
    }),
    effectFilters: z.string().optional().nullable()
  })
});
var AdElementSchema = z.discriminatedUnion("type", [
  AdElementSVGSchema,
  AdElementTextSchema,
  AdElementImageSchema,
  AdElementTeamAssetSchema,
  AdElementVectorSchema
]);
var AdTemplateDataSourceSchema = z.object({
  id: z.string(),
  type: SourceTypeSchema
});
var AdTemplateDataSourcesSchema = z.array(AdTemplateDataSourceSchema);
var DiplayOptionsTeamAssetTypeSchema = z.union([
  z.literal("BADGE"),
  z.literal("JERSEY"),
  z.literal("CUSTOM")
]);
var DiplayOptionsTeamAssetFallbackSchema = z.union([
  z.literal("NONE"),
  z.literal("BADGE"),
  z.literal("JERSEY")
]);
var DiplayOptionsTeamAssetSchema = z.object({
  type: DiplayOptionsTeamAssetTypeSchema,
  fallback: DiplayOptionsTeamAssetFallbackSchema
});
var DisplayOptionsOddsTypeSchema = z.enum([
  "europeanDecimalOdds",
  "americanOdds",
  "decimalOdds",
  "fractionalOdds",
  "impliedProbability",
  "potentialWinnings"
]);
var DisplayOptionsOddsSchema = z.object({
  type: DisplayOptionsOddsTypeSchema,
  currency: z.string().optional().nullable(),
  stake: z.number().optional().nullable()
});
var AdTemplateDiplayOptionsSchema = z.object({
  teamAssets: DiplayOptionsTeamAssetSchema,
  odds: DisplayOptionsOddsSchema.optional().nullable(),
  dateTimeFormat: DateTimeFormatSchema.optional().nullable()
});
var holdingSchema = z.object({
  id: z.number()
});
var BusinessEntitySchema = z.object({
  id: z.string().optional(),
  businessEntityId: z.number(),
  name: z.string(),
  type: z.string(),
  f8OperatorStrings: z.array(z.string()),
  holding: holdingSchema,
  createdAt: z.string().optional(),
  updatedAt: z.string().optional()
});
var AdTemplateSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  brandId: z.string(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  dataSources: z.array(AdTemplateDataSourceSchema),
  adElements: z.array(AdElementSchema),
  displayOptions: AdTemplateDiplayOptionsSchema.optional().nullable(),
  thumbnail: z.string().optional().nullable(),
  isCompetitorFocused: z.boolean().default(false),
  stake: z.number().optional().nullable(),
  oddsFormat: z.string().optional().nullable(),
  supportedMarkets: z.array(z.string()).default([]),
  businessEntityId: z.number().optional().nullable(),
  userId: z.string().optional().nullable(),
  businessEntity: BusinessEntitySchema.optional().nullable()
});
function isDisplayOptionsOddsType(value) {
  return [
    "europeanDecimalOdds",
    "americanOdds",
    "decimalOdds",
    "fractionalOdds",
    "impliedProbability",
    "potentialWinnings"
  ].includes(value);
}
var isSVGElement = discriminate("type", "SVG");
var isTextElement = discriminate("type", "TEXT");
var isImageElement = discriminate("type", "IMAGE");
var isTeamAssetElement = discriminate("type", "TEAM_ASSET");
var isVectorElement = discriminate("type", "VECTOR");
var FontFaceSchema = z.object({
  fontFamily: z.string(),
  weight: z.number(),
  style: z.string(),
  src: z.string()
});

// src/TeamColors.ts
import { deltaE } from "color-delta-e";
function getJerseyColor(arr, teamCompare) {
  let color = getColor(arr, teamCompare);
  if (color === void 0 || isString(color)) {
    return {};
  }
  return Object.entries({ ...color, background: color.base }).reduce(
    (acc, [key, value]) => {
      acc[toCamelCase(key)] = value;
      return acc;
    },
    {}
  );
}
function getHeadwearColor(main, fallbacks) {
  if (main.primary && main.secondary) {
    return { background: main.primary, accent: main.secondary };
  }
  let color = getColor(fallbacks);
  if (color === void 0)
    return {};
  const colorIndex = fallbacks.filter((v) => v !== void 0).findIndex((v) => v === color);
  let accent = colorIndex < fallbacks.length - 1 ? fallbacks[colorIndex + 1] : "#fff";
  color = isString(color) ? color : color.base;
  accent = isString(accent) ? accent : accent?.base || "#fff";
  return {
    background: color,
    accent
  };
}
function getColor(arr, teamCompare) {
  let primary = void 0;
  const thresholds = {
    team: 35
  };
  for (const color of arr) {
    let current = color;
    if (current === void 0)
      continue;
    if (!isString(current)) {
      if (current?.base === void 0)
        continue;
      current = current.base;
    }
    if (teamCompare) {
      const teamDif = Math.floor(deltaE(teamCompare, current));
      if (teamDif < thresholds.team)
        continue;
    }
    primary = color;
    break;
  }
  return primary;
}
function isString(possibleString) {
  return typeof possibleString === "string";
}
var toCamelCase = (str) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};
function getColorByType(competitor, type) {
  if (type === "PRIMARY" && competitor.colors?.primary) {
    return competitor.colors.primary;
  }
  if (type === "SECONDARY" && competitor.colors?.secondary) {
    return competitor.colors.secondary;
  }
  let color = getColor([
    competitor.colors?.home,
    competitor.colors?.away,
    competitor.colors?.third
  ]);
  color = isString(color) ? color : color?.base;
  if (typeof color === "string")
    return color || "";
  return "";
}
function getVectorColor(vectorColorSource, vectorColorType, competitors) {
  switch (vectorColorSource) {
    case "HOME_COLOR":
      return getColorByType(competitors.home, vectorColorType);
    case "AWAY_COLOR":
      return getColorByType(competitors.away, vectorColorType);
  }
}
function getTeamColors(competitors) {
  return {
    AWAY_COLOR: {
      PRIMARY: getVectorColor("AWAY_COLOR", "PRIMARY", competitors),
      SECONDARY: getVectorColor("AWAY_COLOR", "SECONDARY", competitors)
    },
    HOME_COLOR: {
      PRIMARY: getVectorColor("HOME_COLOR", "PRIMARY", competitors),
      SECONDARY: getVectorColor("HOME_COLOR", "SECONDARY", competitors)
    }
  };
}

// src/Console.ts
var Console_exports = {};
__export(Console_exports, {
  stringIsOddType: () => stringIsOddType
});
function stringIsOddType(str) {
  const oddsTypes = [
    "americanOdds",
    "decimalOdds",
    "europeanDecimalOdds",
    "fractionalOdds",
    "impliedProbability",
    "potentialWinnings"
  ];
  return oddsTypes.find((oT) => oT === str) !== void 0;
}

// src/VideoTemplate.ts
var VideoModificationSchema = z.discriminatedUnion("type", [
  AdElementTextSchema.partial({
    id: true,
    position: true,
    size: true
  }),
  AdElementTeamAssetSchema.partial({
    id: true,
    position: true,
    size: true
  })
]);
var VideoTemplateSchema = z.object({
  provider: z.string(),
  providerId: z.string(),
  name: z.string(),
  modifications: z.array(VideoModificationSchema).default([]),
  dataSources: z.array(AdTemplateDataSourceSchema).default([]),
  displayOptions: AdTemplateDiplayOptionsSchema.partial({
    teamAssets: true
  }).optional()
});
var VideoTemplatesSchema = z.object({
  templates: z.array(VideoTemplateSchema).default([])
});
export {
  AdElementDataSourceSchema,
  AdElementImageSchema,
  AdElementSVGSchema,
  AdElementSchema,
  AdElementTeamAssetSchema,
  AdElementTextSchema,
  AdElementVectorSchema,
  AdTemplateDataSourcesSchema,
  AdTemplateSchema,
  AdflowAPI_exports as AdflowAPI,
  BusinessEntitySchema,
  Console_exports as Console,
  Datasources_exports as Datasources,
  FontFaceSchema,
  TeamAssetElement_exports as TeamAssetTypes,
  VideoTemplateSchema,
  VideoTemplatesSchema,
  discriminate,
  getColor,
  getHeadwearColor,
  getJerseyColor,
  getTeamColors,
  isDisplayOptionsOddsType,
  isImageElement,
  isSVGElement,
  isTeamAssetElement,
  isTextElement,
  isVectorElement,
  notEmpty,
  stringIsOfUnionType
};
