import { FC } from "react";

import { AdElementText } from "@adflow/types";
import { Flex, FormControl, FormLabel } from "@chakra-ui/react";

import DayFormatControls, { isDayPresent } from "./DayFormatControls";
import TimeFormatControls, { isTimePresent } from "./TimeFormatControls";
import MonthFormatControls, { isMonthPresent } from "./MonthFormatControls";

type Props = {
  selectedElement: AdElementText;
};

export const isDateTimePresent = (textElement: AdElementText) => {
  return (
    isDayPresent(textElement) ||
    isTimePresent(textElement) ||
    isMonthPresent(textElement)
  );
};

const DateTimeControls: FC<Props> = ({ selectedElement }) => {
  return (
    <FormControl alignItems='center' mt={5} mb={2} mr={0}>
      <Flex direction='column'>
        {isTimePresent(selectedElement) && (
          <TimeFormatControls textElement={selectedElement} />
        )}
        {(isMonthPresent(selectedElement) || isDayPresent(selectedElement)) && (
          <FormLabel htmlFor='date-format' mb={"1"} fontWeight={"semibold"}>
            Date Format
          </FormLabel>
        )}
        {isMonthPresent(selectedElement) && (
          <MonthFormatControls textElement={selectedElement} />
        )}
        {isDayPresent(selectedElement) && (
          <DayFormatControls textElement={selectedElement} />
        )}
      </Flex>
    </FormControl>
  );
};

DateTimeControls.displayName = "DateTimeControls";

export default DateTimeControls;
