import { FC, useMemo } from "react";

import { Ad } from "@adflow/ad";
import { ConsoleAPIGraph } from "@adflow/graphqlgen";
import {
  AdElement,
  AdElementDataSource,
  AdElementImage,
  AdElementVector,
  AdElementVectorColor,
  AdflowAPI,
  Console,
  isImageElement,
  isTeamAssetElement,
  isTextElement,
  isVectorElement,
  DateTimeFormat,
  isDisplayOptionsOddsType
} from "@adflow/types";
import { formatOdds, fmtReference } from "@adflow/utils";
import { Box } from "@chakra-ui/react";

import PreviewOverlay from "../PreviewOverlay";
import SelectOverlay from "../SelectOverlay";
import { useLoadFontsForAd } from "../../useFonts";

type Props = {
  elements: Array<AdElement>;
  brand: ConsoleAPIGraph.Brand | null;
  fonts: Array<{
    fontFamily: string;
    weight: number;
    style: string;
    src: string;
  }>;
  displayOptions?: AdflowAPI.AdTemplateDisplayOptions | null;
  selectedElementId?: string | null;
  isIndexPreview: boolean;
};

const locale = navigator.language || "en-GB";

const previewDate = new Date("Thu, 05 Oct 2023 15:30:00 +0000");

// in the ad server, each of these keys would be a method for retrieving the
// related data from their source e.g event payload.
const MockEventDataSource = {
  COMPETITION_NAME: "La Liga",
  DAY: previewDate,
  DATE: "5",
  MONTH: previewDate,
  TIME: previewDate,
  ODDS: [10, 1],
  WAS_ODDS: [5, 1],
  HOME_ODDS: [12, 2],
  AWAY_ODDS: [5, 3],
  DRAW_ODDS: [20, 1],
  COMPETITOR_ODDS: [10, 1],
  OPPONENT_ODDS: [5, 1],
  MARKET_NAME: "90 Minutes",
  INSIGHT_TEXT: "Insightful insight",
  EVENT_NAME: "Some team vs Another",
  COMPETITOR_NAME: "Competitor",
  COMPETITOR_MICRO_NAME: "COM",
  OPPONENT_NAME: "Opponent",
  OPPONENT_MICRO_NAME: "OPP",
  HOME_NAME: "Home Team",
  AWAY_NAME: "Away Team",
  HOME_NAME_MICRO: "HOM",
  AWAY_NAME_MICRO: "AWA",
  PROMO_ODDS: [10, 1],
  PROMO_WAS_ODDS: [5, 1],
  PROMO_TEXT:
    "The next person to score will also be the next person to get sent off for headbutting the Ref.",
  OVER_ODDS: [10, 11],
  UNDER_ODDS: [10, 11],
  TOTAL: "47.5",
  HOME_SPREAD: "+2.5",
  AWAY_SPREAD: "-2.5",
  SPORT_NAME: "Football",
  SPORT_ID: "1",
  VENUE: "WWK Arena",
  VENUE_ID: "321",
  CITY_NAME: "Augsburg",
  SPORT: "Football",
  STAKE: "£10",
  HOME_ASSET: {
    JERSEY: {
      type: "kit",
      data: {
        kind: "shortSleeveJersey",
        colours: {
          sleeve: "#D62D2E",
          background: "#224076"
        }
      }
    },
    BADGE: {
      type: "badge",
      data: {
        content: "https://img.sportradar.com/ls/crest/big/33.png"
      }
    },
    CUSTOM: {
      type: "kit",
      data: {
        kind: "shortSleeveJersey",
        colours: {
          sleeve: "#D62D2E",
          background: "#224076"
        }
      }
    }
  },
  AWAY_ASSET: {
    JERSEY: {
      type: "kit",
      data: {
        kind: "shortSleeveJersey",
        colours: {
          sleeve: "#224076",
          background: "#D62D2E"
        }
      }
    },
    BADGE: {
      type: "badge",
      data: {
        content: "https://img.sportradar.com/ls/crest/big/43.png"
      }
    },
    CUSTOM: {
      type: "kit",
      data: {
        kind: "shortSleeveJersey",
        colours: {
          sleeve: "#224076",
          background: "#D62D2E"
        }
      }
    }
  },
  COMPETITOR_ID: "23",
  FOCUS_COMPETITOR_ID: "",
  OPPONENT_COMPETITOR_ID: "",
  HOME_COMPETITOR_ID: "",
  AWAY_COMPETITOR_ID: "",
  FOCUS_COMPETITOR_COUNTRY_CODE: "",
  OPPONENT_COMPETITOR_COUNTRY_CODE: "",
  HOME_COMPETITOR_COUNTRY_CODE: "",
  AWAY_COMPETITOR_COUNTRY_CODE: "",
  HOME_COLOR_PRIMARY: "",
  AWAY_COLOR_PRIMARY: "",
  HOME_COLOR_SECONDARY: "",
  AWAY_COLOR_SECONDARY: "",
  COMPETITION_ID: "17",
  OPTION_1_ODDS: [1, 1],
  OPTION_2_ODDS: [2, 1],
  OPTION_3_ODDS: [3, 1],
  OPTION_4_ODDS: [4, 1],
  OPTION_5_ODDS: [5, 1],
  OPTION_6_ODDS: [6, 1],
  OPTION_7_ODDS: [7, 1],
  OPTION_8_ODDS: [8, 1],
  OPTION_9_ODDS: [9, 1],
  OPTION_10_ODDS: [10, 1],
  OPTION_1_NAME: "Option 1",
  OPTION_2_NAME: "Option 2",
  OPTION_3_NAME: "Option 3",
  OPTION_4_NAME: "Option 4",
  OPTION_5_NAME: "Option 5",
  OPTION_6_NAME: "Option 6",
  OPTION_7_NAME: "Option 7",
  OPTION_8_NAME: "Option 8",
  OPTION_9_NAME: "Option 9",
  OPTION_10_NAME: "Option 10",
  OPTION_1_COMPETITOR_ID: "1",
  OPTION_2_COMPETITOR_ID: "2",
  OPTION_3_COMPETITOR_ID: "3",
  OPTION_4_COMPETITOR_ID: "4",
  OPTION_5_COMPETITOR_ID: "5",
  OPTION_6_COMPETITOR_ID: "6",
  OPTION_7_COMPETITOR_ID: "7",
  OPTION_8_COMPETITOR_ID: "8",
  OPTION_9_COMPETITOR_ID: "9",
  OPTION_10_COMPETITOR_ID: "10",
  OPTION_1_OUTCOME_NAME: "Outcome 1",
  OPTION_2_OUTCOME_NAME: "Outcome 2",
  OPTION_3_OUTCOME_NAME: "Outcome 3",
  OPTION_4_OUTCOME_NAME: "Outcome 4",
  OPTION_5_OUTCOME_NAME: "Outcome 5",
  OPTION_1_MARKET_NAME: "Market 1",
  OPTION_2_MARKET_NAME: "Market 2",
  OPTION_3_MARKET_NAME: "Market 3",
  OPTION_4_MARKET_NAME: "Market 4",
  OPTION_5_MARKET_NAME: "Market 5",
  MAIN_VIDEO: undefined,
  OUTRIGHT_NAME: "Outright"
} as const;

const Preview: FC<Props> = ({
  elements,
  brand,
  fonts,
  displayOptions,
  selectedElementId,
  isIndexPreview
}) => {
  const dateTimeFormat = useMemo(
    () =>
      displayOptions?.dateTimeFormat ||
      ({
        timeFormat: "hour24",
        dayFormat: "long",
        monthFormat: "long",
        showTimezone: false
      } as DateTimeFormat),
    [displayOptions?.dateTimeFormat]
  );

  // new oddsDisplay format is set in displayOptions and is decimalOdds by defaults
  const oddsDisplayFormat = useMemo(
    () =>
      displayOptions?.odds?.type &&
      isDisplayOptionsOddsType(displayOptions.odds.type)
        ? displayOptions.odds.type
        : "decimalOdds",
    [displayOptions?.odds?.type]
  );

  const potentialWinnings = useMemo(
    () =>
      brand?.adTemplate?.potentialWinnings || { stake: 10, currency: "GBP" },
    [brand?.adTemplate?.potentialWinnings]
  );

  const mappedElements = useMemo(
    () =>
      elements.map(el => {
        const cloned = structuredClone(el) as AdElement;

        if (isTextElement(cloned)) {
          cloned.data.content = templateString(
            cloned.data.content,
            cloned.sources,
            oddsDisplayFormat,
            potentialWinnings,
            dateTimeFormat
          );
        }

        if (isTeamAssetElement(cloned)) {
          const assetType = displayOptions?.teamAssets?.type ?? "JERSEY";

          return {
            ...cloned,
            data: {
              content: cloned.data.content,
              source: cloned.data.source,
              display:
                cloned.data.source === "HOME_ASSET" ||
                cloned.data.source === "COMPETITOR_ASSET"
                  ? MockEventDataSource.HOME_ASSET[assetType]
                  : MockEventDataSource.AWAY_ASSET[assetType]
            }
          };
        }

        if (isVectorElement(cloned)) {
          const fill = cloned.data.color.fill;
          const stroke = cloned.data.color.stroke;
          fill && updateVectorColor(fill, 0);

          stroke && updateVectorColor(stroke, 1);

          return {
            ...cloned,
            data: {
              ...cloned.data,
              color: {
                fill,
                stroke,
                strokeWeight: cloned.data.color.strokeWeight
              }
            }
          } satisfies AdElementVector;
        }

        if (isImageElement(cloned)) {
          return {
            ...cloned,
            data: {
              ...cloned.data,
              content: cloned.data.initialContent ?? cloned.data.content
            }
          } satisfies AdElementImage;
        }

        return cloned;
      }),
    [elements, oddsDisplayFormat, potentialWinnings, displayOptions]
  );

  const mappedElementsWithoutSvg = useMemo(
    () => mappedElements.filter(element => element.type != "SVG"),
    [mappedElements]
  );

  const svgElement = useMemo(
    () => elements.find(x => x.type === "SVG"),
    [elements]
  );
  const transformScale = useMemo(() => {
    if (svgElement?.size.width && svgElement?.size.height) {
      const scaleX =
        svgElement.size.width > 600 ? 600 / svgElement.size.width : 1;
      const scaleY =
        svgElement.size.height > 600 ? 600 / svgElement.size.height : 1;
      return Math.min(scaleX, scaleY);
    }
    return 1;
  }, [svgElement]);

  const boxWidth = useMemo(
    () =>
      svgElement?.size.width ? svgElement?.size.width * transformScale : 600,
    [transformScale, svgElement]
  );
  const boxHeight = useMemo(
    () =>
      svgElement?.size.height ? svgElement?.size.height * transformScale : 600,
    [transformScale, svgElement]
  );
  const transformOrigin =
    isIndexPreview &&
    svgElement?.size.width === 1200 &&
    svgElement?.size.height === 1200
      ? "top left"
      : isIndexPreview
      ? "top"
      : "top left";

  useLoadFontsForAd(mappedElements, fonts);

  return (
    <Box
      position='relative'
      sx={{
        width: `${boxWidth}px`,
        height: `${boxHeight}px`,
        transform: `scale(${transformScale})`,
        transformOrigin: transformOrigin
      }}
    >
      <Ad adElements={mappedElements} isPreview={true} />
      {isIndexPreview ? (
        <></>
      ) : (
        <>
          <PreviewOverlay
            adElements={mappedElementsWithoutSvg}
            selectedId={selectedElementId}
          />
          <SelectOverlay adElements={mappedElementsWithoutSvg} />
        </>
      )}
    </Box>
  );
};

Preview.displayName = "Preview";

export default Preview;
export type { Props as PreviewProps };

function templateString(
  templatedStr: string,
  sources: Array<AdElementDataSource>,
  oddsDisplayFormat: Console.OddsType = "decimalOdds",
  potentialWinnings: ConsoleAPIGraph.PotentialWinnings,
  dateTimeFormat: DateTimeFormat
) {
  let replacedStr = templatedStr;

  sources.forEach(dSource => {
    const data = MockEventDataSource[dSource.name];

    let dataString = "";

    switch (typeof data) {
      case "string":
        dataString = data;
        break;
      case "object":
        if (data instanceof Date)
          dataString = fmtReference(
            data,
            locale,
            "Etc/UTC",
            dSource.name,
            dateTimeFormat
          );
        else
          dataString = formatOdds(
            [...data],
            oddsDisplayFormat,
            potentialWinnings.stake,
            potentialWinnings.currency,
            locale
          );
        break;
    }

    replacedStr = replacedStr.replace(
      `{${dSource.sourceId}:${dSource.name}}`,
      dataString
    );
  });

  return replacedStr;
}

function fmtDate(date: Date) {
  const weekdayDay = date.toLocaleDateString(locale, {
    weekday: "long"
  });

  const day = date.toLocaleDateString(locale, {
    day: "2-digit"
  });

  const month = date.toLocaleDateString(locale, {
    month: "long"
  });

  const time = date.toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit"
  });

  return `${weekdayDay} ${day} ${month} ${time}`.toUpperCase();
}
/**
 *
 * @param color
 * @param palette which colors do you desire?
 */

function updateVectorColor(color: AdElementVectorColor, palette: 0 | 1) {
  const palettes = [
    {
      HOME_COLOR: {
        PRIMARY: "red",
        SECONDARY: "green"
      },
      AWAY_COLOR: {
        PRIMARY: "blue",
        SECONDARY: "cyan"
      },
      COMPETITOR_COLOR: {
        PRIMARY: "yellow",
        SECONDARY: "pink"
      },
      OPPONENT_COLOR: {
        PRIMARY: "white",
        SECONDARY: "purple"
      }
    },
    {
      HOME_COLOR: {
        PRIMARY: "magenta",
        SECONDARY: "yellow"
      },
      AWAY_COLOR: {
        PRIMARY: "black",
        SECONDARY: "purple"
      },
      COMPETITOR_COLOR: {
        PRIMARY: "yellow",
        SECONDARY: "pink"
      },
      OPPONENT_COLOR: {
        PRIMARY: "white",
        SECONDARY: "purple"
      }
    }
  ];
  if (color.source && color.type) {
    color.value = palettes[palette][color.source]?.[color.type] ?? "";
  }
}
